export const getDeviceList = async (apiCall, scopeId) => {
  return await apiCall(`/scope/device/list?scope=${scopeId}`, "GET");
};

export const getRoomList = async (apiCall, scopeId) => {
  return await apiCall(`/scope/room/list?scope=${scopeId}`, "GET");
};

export const getRoom = async (apiCall, scopeId, roomId) => {
  return await apiCall(
    `/scope/room/get?scope=${scopeId}&room=${roomId}`,
    "GET"
  );
};

export const saveRoom = async (apiCall, room) => {
  const response = await apiCall("/scope/room/save", "POST", room);
  return response && response.success;
};

export const deleteRoom = async (apiCall, scopeId, roomId) => {
  const request = { scopeId, roomId };
  const response = await apiCall("/scope/room/delete", "POST", request);
  return response && response.success;
};
