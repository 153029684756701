import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { listIntegrations } from "./integration-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import ConnectDialog from "../common/connect-dialog";
import Grid from "@mui/material/Grid";
import IntegrationsFilter from "./integrations-filter";
import BuildTwoToneIcon from "@mui/icons-material/BuildTwoTone";
import FaceTwoToneIcon from "@mui/icons-material/FaceTwoTone";
import PhonelinkSetupTwoToneIcon from "@mui/icons-material/PhonelinkSetupTwoTone";
import ScheduleTwoToneIcon from "@mui/icons-material/ScheduleTwoTone";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import DeviceHubTwoToneIcon from "@mui/icons-material/DeviceHubTwoTone";

const IntegrationListView = ({ showFilter = false, setShowFilter }) => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const [integrations, setIntegrations] = useState([]);
  const [connectIntegrationName, setConnectIntegrationName] = useState("");
  const [connectInfo, setConnectInfo] = useState(null);
  const [openConnect, setOpenConnect] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(showFilter);
  const [filterText, setFilterText] = useState("");
  const [sortBy, setSortBy] = useState("alphabet");
  const history = useHistory();

  useEffect(() => {
    setFilterText("");
    setIsShowFilter(showFilter);
  }, [showFilter]);

  useEffect(() => {
    handleLoadIntegrations();
  }, []);

  const handleLoadIntegrations = async () => {
    const resp = await listIntegrations(apiCall, userInfo.activeScope.id);
    console.log(resp);
    if (resp) {
      setIntegrations(resp.integrations);
    } else {
      errorToast("Error loading integrations!");
    }
  };

  const handleCardClick = (integration) => {
    const integrationId = integration.integration;
    history.push(`/integration?id=${integrationId}`);
  };

  const sortedIntegrations = integrations
    .filter((a) =>
      a.integration.toLowerCase().includes(filterText.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "alphabet") {
        const aName = a.integration.toLowerCase();
        const bName = b.integration.toLowerCase();

        const aIsLetter = /^[a-zA-Z]/.test(aName);
        const bIsLetter = /^[a-zA-Z]/.test(bName);
        if (aIsLetter && !bIsLetter) return -1;
        if (!aIsLetter && bIsLetter) return 1;
        return aName.localeCompare(bName); //sort alphabetically
      }
    });

  const getLogo = (name) => {
    name = name.trim().toLowerCase();

    switch (name) {
      case "chat":
        return (
          <FaceTwoToneIcon
            sx={{
              width: "35%",
              height: "35%",
            }}
          />
        );
      case "devices":
        return (
          <BuildTwoToneIcon
            sx={{
              width: "35%",
              height: "35%",
            }}
          />
        );
      case "phone":
        return (
          <PhonelinkSetupTwoToneIcon
            sx={{
              width: "35%",
              height: "35%",
            }}
          />
        );
      case "time & schedule":
        return (
          <ScheduleTwoToneIcon
            sx={{
              width: "35%",
              height: "35%",
            }}
          />
        );
      case "weather":
        return (
          <WbSunnyTwoToneIcon
            sx={{
              width: "35%",
              height: "35%",
            }}
          />
        );
      default:
        return (
          <DeviceHubTwoToneIcon
            sx={{
              width: "40%",
              height: "40%",
            }}
          />
        );
    }
  };

  return (
    <Box sx={{ flexGrow: 1, height: "100%", m: 2, pb: "80px" }}>
      {isShowFilter && (
        <IntegrationsFilter
          sortBy={sortBy}
          setSortBy={setSortBy}
          setFilterText={setFilterText}
          setShowFilter={setShowFilter}
        />
      )}
      <Grid container spacing={2}>
        {sortedIntegrations.map((integration) => (
          <Grid item xs={6} sm={3} md={3} lg={2} key={integration.integration}>
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                padding: 1.5, // Reduced padding
                cursor: "pointer",
                backgroundColor: integration.installed
                  ? theme.palette.background.paper
                  : theme.palette.background.disabled,
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: theme.shadows[4],
                  backgroundColor: theme.palette.action.hover,
                },
              }}
              onClick={() => handleCardClick(integration)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                    mt: 2,
                  }}
                >
                  {getLogo(integration.name)}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 0.5, // Reduced margin
                  }}
                >
                  <Typography
                    variant="subtitle1" // Smaller text
                    noWrap
                    sx={{
                      fontWeight: "medium",
                      flex: 1,
                      mr: 1,
                    }}
                  >
                    {integration.name}
                  </Typography>
                  {integration.installed && (
                    <DownloadDoneIcon
                      color="success"
                      sx={{ fontSize: 20 }} // Smaller icon
                    />
                  )}
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.75rem", // Smaller text
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {integration.org}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <ConnectDialog
        open={openConnect}
        integration={connectIntegrationName}
        connectInfo={connectInfo}
        onClose={() => setOpenConnect(false)}
        onSuccess={() => successToast("Linked!")}
        onFailure={() => errorToast("Link error!")}
      />
    </Box>
  );
};

export default IntegrationListView;
