/*
 * @Author: frost 2723713040@qq.com
 * @Date: 2024-12-02 11:01:29
 * @LastEditors: frost 2723713040@qq.com
 * @LastEditTime: 2024-12-03 14:28:00
 * @FilePath: \pallas-cat\src\setting\room.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  Card,
  CardContent,
  Grid,
  Divider,
  Collapse,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { RemoveCircle as RemoveCircleIcon } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import LoadingSpinner from "../common/loading";
import {
  getRoomList,
  getRoom,
  saveRoom,
  deleteRoom,
  getDeviceList,
} from "./room-service.js";
import SearchableSelectInput from "../input/searchable-select-input";
import { getUserInfo } from "./setting-service";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import { useHistory } from "react-router-dom";

const Room = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [roomList, setRoomList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [newRoomName, setNewRoomName] = useState("");
  const [editedRoomName, setEditedRoomName] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { successToast, warningToast, errorToast } = useToast();
  const [openRoomId, setOpenRoomId] = useState(null);
  const [editRoomId, setEditRoomId] = useState(null);
  const history = useHistory();

  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiCall } = useApiCall();
  const [error, setError] = useState("");
  const [selectedDevice, setSelectedDevice] = useState(null);

  const [roomDeviceIds, setRoomDeviceIds] = useState(null);
  const [isOccupy, setIsOccupy] = useState("");

  useEffect(() => {
    handleRoomAndDevice();
  }, []);

  const handleRoomAndDevice = async () => {
    setLoading(true);
    await Promise.all([handleRoomList(), handleDeviceList()]);
    setLoading(false);
  };

  const handleDeviceList = async () => {
    try {
      const resp = await getDeviceList(apiCall, userInfo.activeScope.id);
      if (resp) {
        setDeviceList(resp);
      }
    } catch (error) {
      console.error("Error fetching device info:", error);
    }
  };

  const handleRoomList = async () => {
    const resp = await getRoomList(apiCall, userInfo.activeScope.id);
    if (resp) {
      setRoomList(resp.rooms);
    }
  };

  const handleRoomInfo = async (roomId) => {
    const resp = await getRoom(apiCall, userInfo.activeScope.id, roomId);
    if (resp) {
      if (resp.devices) {
        setRoomDeviceIds(new Set(resp.devices.map((device) => device.id)));
      }
    } else {
      errorToast("Error!");
    }
  };

  const handleAddRoomOpen = () => {
    if (isOccupy == "") {
      setIsOccupy("add room");
      setOpenAddDialog(true);
    }
  };

  const handleAddRoomClose = () => {
    setOpenAddDialog(false);
    setIsOccupy("");
  };

  const handleCheckRoomName = (e) => {
    const name = e.target.value;
    if (name != null) {
      setNewRoomName(name);
      if (roomList) {
        if (
          roomList.some((room) => {
            return (
              name.trim().toLowerCase() === room.roomName.trim().toLowerCase()
            );
          })
        ) {
          setError("Room name already exists.");
        } else {
          setError("");
        }
      }
    } else {
      return;
    }
  };

  const handleSaveRoom = async (room) => {
    const resp = await saveRoom(apiCall, room);
    if (resp) {
      successToast("saved");
    } else {
      errorToast("error");
    }
  };

  const handleAddRoom = async () => {
    const room = {
      scopeId: userInfo.activeScope.id,
      roomId: null,
      roomName: newRoomName,
      devices: [],
    };
    await handleSaveRoom(room);
    await handleRoomList();
    handleAddRoomClose();
    setNewRoomName("");
  };

  const handleEditChange = (value, name) => {
    if (value.trim() == "") {
      setError("Room name cannot be empty");
    } else if (roomList) {
      if (
        roomList.some((room) => {
          if (room.roomName !== name)
            return (
              value.trim().toLowerCase() === room.roomName.trim().toLowerCase()
            );
        })
      ) {
        setError("Room name exists.");
      } else {
        setError("");
      }
    }
    setEditedRoomName(value);
  };

  const handleEditRoom = async (room) => {
    setEditRoomId((prevEditRoomId) => {
      return prevEditRoomId === room.roomId ? null : room.roomId;
    });

    if (room.roomName !== editedRoomName) {
      room.roomName = editedRoomName;
      await handleSaveRoom(room);
      await handleRoomList();
    }

    setEditedRoomName("");
  };

  const handleDeleteRoom = async (roomId) => {
    const resp = await deleteRoom(apiCall, userInfo.activeScope.id, roomId);
    if (resp) {
      successToast("deleted");
      handleRoomList();
    } else {
      errorToast("error");
    }
  };

  const handleOpenToggle = (roomId) => {
    setOpenRoomId((prevOpenRoomId) => {
      return prevOpenRoomId === roomId ? null : roomId;
    });
  };

  const handleEditRoomToggle = (roomId, name) => {
    setEditRoomId((prevEditRoomId) => {
      return prevEditRoomId === roomId ? null : roomId;
    });
    setEditedRoomName(name);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleDeleteDevice = async (room, deviceId) => {
    const updateRoom = {
      ...room,
      devices: room.devices?.filter((device) => device.id !== deviceId),
    };

    await handleSaveRoom(updateRoom);
    await handleRoomList();
    await handleRoomInfo(room.roomId);
  };

  const handleAddDevice = async (room) => {
    if (selectedDevice) {
      setIsOccupy("add device");
      const isExist = room.devices.some((device) => {
        device.id === selectedDevice.id;
      });
      if (isExist) {
        errorToast("error!");
        setIsOccupy("");
        return;
      }
      const deviceToAdd = {
        roomId: room.roomId,
        id: selectedDevice.id,
        name: selectedDevice.name,
        integration: selectedDevice.integration,
      };
      room.devices.push(deviceToAdd);
      await handleSaveRoom(room);
      await handleRoomList();
      await handleRoomInfo(room.roomId);
      setSelectedDevice(null);
      setIsOccupy("");
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="start"
          flexDirection="column"
          height="100%"
          p={2}
          width="100%"
        >
          <Box
            position="static"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {isSmallScreen && (
                <IconButton
                  color="inherit"
                  edge="start"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    if (isSmallScreen) {
                      history.goBack();
                    } else {
                      history.replace("/setting");
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}

              <Box sx={{ display: "flex" }}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  Room
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                sx={{ color: theme.palette.text.primary }}
                onClick={handleEditToggle}
              >
                {isEditing ? <EditOffIcon /> : <EditIcon />}
              </IconButton>
            </Box>
          </Box>

          {isEditing && (
            <Card
              sx={{
                width: "100%",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                border: "2px dashed #ddd",
                transition: "border-color 0.2s",
                "&:hover": {
                  borderColor: "#ccc",
                },
                mb: 2,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton onClick={handleAddRoomOpen}>
                  <AddIcon fontSize="large" />
                </IconButton>
                <Typography variant="h6" color="textSecondary">
                  Add New Room
                </Typography>
              </CardContent>
            </Card>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {roomList
                .sort((a, b) =>
                  (a.roomName || "").localeCompare(b.roomName || "")
                )
                .map((room) => (
                  <Card
                    key={room.roomId}
                    sx={{
                      p: 2,
                      mb: 1,
                      width: "100%",
                      dispaly: "flex",
                      justifyContent: "space-between",
                      backgroundColor: theme.palette.background.card,
                      boxShadow: "none",
                      fontFamily: "Roboto, sans-serif",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {editRoomId == room.roomId ? (
                        <Box
                          sx={{
                            mr: 1,
                          }}
                        >
                          <TextField
                            fullWidth
                            value={editedRoomName}
                            onChange={(e) =>
                              handleEditChange(e.target.value, room.roomName)
                            }
                            label="Room Name"
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "5px",
                                "& input": {
                                  p: 1.2,
                                },
                              },
                              "& .MuiInputLabel-root": { lineHeight: "0.9" },
                            }}
                            error={!!error}
                            helperText={error}
                          />
                        </Box>
                      ) : (
                        <Typography>{room.roomName}</Typography>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        {isEditing && (
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (editRoomId == room.roomId) {
                                  handleEditRoom(room);
                                  setIsOccupy("");
                                } else {
                                  setIsOccupy("edit room");
                                  handleEditRoomToggle(
                                    room.roomId,
                                    room.roomName
                                  );
                                }
                              }}
                              disabled={
                                (editRoomId === room.roomId && !!error) ||
                                (editRoomId !== room.roomId &&
                                  isOccupy !== "") ||
                                (isOccupy !== "edit room" && isOccupy !== "")
                              }
                            >
                              {editRoomId == room.roomId ? (
                                <Typography>save</Typography>
                              ) : (
                                <Typography>edit</Typography>
                              )}
                            </Button>
                            <IconButton
                              onClick={() => handleDeleteRoom(room.roomId)}
                              color="error"
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Box>
                        )}
                        <Box>
                          <IconButton
                            onClick={() => {
                              handleOpenToggle(room.roomId);
                              if (openRoomId !== room.roomId) {
                                handleRoomInfo(room.roomId);
                              }
                            }}
                          >
                            {openRoomId === room.roomId ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    {/* This is the expanded content */}
                    <Collapse in={openRoomId === room.roomId}>
                      <Divider />

                      {room.devices?.map((device) => (
                        <Box
                          key={device.id}
                          sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>{device.name}</Typography>

                          <IconButton
                            color="error"
                            onClick={() => handleDeleteDevice(room, device.id)}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Box>
                      ))}
                      <>
                        <Divider sx={{ my: 1 }} />
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box sx={{ mr: 1, width: "100%" }}>
                            <Autocomplete
                              ListboxProps={{
                                style: { maxHeight: "200px" },
                              }}
                              options={deviceList}
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value?.id
                              }
                              onChange={(event, newValue) =>
                                setSelectedDevice(newValue)
                              }
                              value={selectedDevice}
                              renderOption={(props, option) => {
                                const isDisabled = roomDeviceIds
                                  ? roomDeviceIds.has(option.id)
                                  : false;
                                const { key, ...tagProps } = props;
                                return (
                                  <li
                                    {...tagProps}
                                    key={key}
                                    style={{
                                      pointerEvents: isDisabled
                                        ? "none"
                                        : "auto",
                                      color: isDisabled ? "gray" : "black",
                                    }}
                                  >
                                    <Typography variant="body1">
                                      {option.name} {isDisabled && "(assigned)"}
                                    </Typography>
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="select device"
                                  placeholder="device name"
                                />
                              )}
                            />
                          </Box>

                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              handleAddDevice(room);
                            }}
                            sx={{ padding: 1.2, mt: 1 }}
                            disabled={isOccupy !== ""}
                          >
                            add
                          </Button>
                        </Box>
                      </>
                    </Collapse>
                  </Card>
                ))}
            </Grid>
          </Grid>

          <Dialog open={openAddDialog} onClose={handleAddRoomClose}>
            <DialogContent>
              <DialogTitle sx={{ p: 0, mb: 4 }}>Add Room</DialogTitle>
              <TextField
                label="Room Name"
                fullWidth
                variant="outlined"
                value={newRoomName}
                onChange={(e) => handleCheckRoomName(e)}
                error={!!error}
                helperText={error}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddRoomClose}>cancel</Button>
              <Button
                onClick={() => {
                  handleAddRoom();
                }}
                disabled={!!error}
              >
                add
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default Room;
