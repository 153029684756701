import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Avatar,
  Grid,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Container,
  MenuItem,
  Select,
  DialogTitle,
  useMediaQuery,
  Card,
  Tooltip,
  Paper,
} from "@mui/material";
import {
  originalPathState,
  userInfoState,
  developerEmailState,
} from "../global-state";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { RemoveCircle as RemoveCircleIcon } from "@mui/icons-material";
import useApiCall from "../common/api-call";
import {
  saveScope,
  deleteScope,
  deleteScopeMembership,
  inviteMember,
  updateMember,
  getScopeInfo,
  exitScope,
  switchScope,
  saveScopeExecutionEnv,
} from "./scope-service";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { getUserInfo } from "./setting-service";
import { useHistory, useLocation } from "react-router-dom";
import LoadingSpinner from "../common/loading";
import InfoIcon from "@mui/icons-material/Info";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useToast } from "../common/toast";
import {
  Edit2,
  X,
  Trash2,
  LogOut,
  Plus,
  Check,
  UserPlus,
  Settings,
} from "lucide-react";

const Scope = () => {
  const [selectedScope, setSelectedScope] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isScopeEditing, setIsScopeEditing] = useState(false);
  const [newScopeName, setNewScopeName] = useState("");
  const [newMembers, setNewMembers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [scopes, setScopes] = useState([]);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const [developerEmail, setDeveloperEmail] =
    useRecoilState(developerEmailState);
  const { successToast, warningToast, errorToast } = useToast();
  const [error, setError] = useState("");
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [currentOperation, setCurrentOperation] = useState(null); // type: 'EDIT_MEMBERS', 'EDIT_SCOPE', 'ADD_SCOPE', null

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    setEditedName(selectedScope.name);
  }, [scopes, selectedScope]);

  const fetchUserInfo = async () => {
    setLoading(true);

    const resp = await getUserInfo(apiCall, userInfo);
    if (resp) {
      setUserInfo(resp);
      console.log(resp);
      if (resp.scopes === null) {
        history.push("/init-scope");
        return;
      }
      setScopes(resp.scopes);
      if (resp.activeScope === null) {
        history.push("/init-scope");
        return;
      }
      setSelectedScope(resp.activeScope);
      handleScopeInfo(resp.activeScope.id);
    } else {
      errorToast("Error!");
    }
    setLoading(false);
  };

  const handleScopeInfo = async (scopeId) => {
    try {
      const resp = await getScopeInfo(apiCall, scopeId);
      console.log(resp);
      if (resp && resp.members) {
        setFamilyMembers(resp.members);
        const o = resp.members.some(
          (member) => member.email === userInfo.name && member.role === "OWNER"
        );
        if (o) {
          setIsOwner(true);
        } else {
          setIsOwner(false);
        }
        let env = resp.executionEnv;
        if (env === "LOCAL-" + developerEmail) {
          env = "LOCAL";
        }
        setSelectedEnv(env);
      }
    } catch (error) {
      console.error("Error fetching scope info:", error);
    }
  };

  const startOperation = (operation) => {
    if (currentOperation) {
      warningToast("Please complete current operation first");
      return false;
    }
    setCurrentOperation(operation);
    return true;
  };

  const endOperation = () => {
    setCurrentOperation(null);
  };

  const handleEditScope = () => {
    if (startOperation("EDIT_SCOPE")) {
      setIsScopeEditing(true);
      setEditedName(selectedScope.name);
    }
  };

  const handleExitScope = async () => {
    const resp = await exitScope(
      apiCall,
      selectedScope.id,
      selectedScope.pending,
      userInfo.email
    );
    if (resp) {
      successToast("Exited");
    } else {
      errorToast("Error!");
    }
    fetchUserInfo();
  };

  const handleSaveActiveScope = async (oldId, scopeId) => {
    if (oldId === scopeId) {
      return;
    }
    const resp = await switchScope(apiCall, oldId, scopeId);

    if (resp) {
      // TODO: remove
      fetchUserInfo();
    } else {
      errorToast("Error!");
    }
  };

  const handleSaveEditScope = async () => {
    await saveScope(apiCall, selectedScope.id, editedName);
    fetchUserInfo();
    setIsScopeEditing(false);
    endOperation();
  };

  const handleCancelEditScope = () => {
    setIsScopeEditing(false);
    setEditedName(selectedScope.name);
    endOperation();
  };

  const handleDeleteScope = async () => {
    const resp = await deleteScope(apiCall, selectedScope.id);
    if (resp) {
      successToast("Deleted");
    } else {
      errorToast("Error!");
    }
    fetchUserInfo();
  };

  const isDuplicateName = (name) => {
    return scopes.some(
      (scope) => scope.name.toLowerCase() === name.toLowerCase().trim()
    );
  };

  const checkDuplicateName = (name) => {
    const trimmedName = name.toLowerCase().trim();
    if (!trimmedName) {
      setError("name cannot be empty");
      return true;
    } else if (isDuplicateName(trimmedName)) {
      setError("name already exists");
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const handleAddScope = async () => {
    setError("");
    const trimmedName = newScopeName.toLowerCase().trim();
    if (!trimmedName) {
      setError("name cannot be empty");
      return;
    } else if (checkDuplicateName(trimmedName)) {
      return;
    }

    const scopeId = await saveScope(apiCall, null, newScopeName);
    handleSaveActiveScope(selectedScope.id, scopeId);
    setOpenDialog(false);
    setNewScopeName("");
    setError("");
  };

  const handleScopeChange = (event) => {
    const scope = scopes.find((s) => s.name === event.target.value);
    const oldId = selectedScope.id;
    setSelectedScope(scope);
    handleSaveActiveScope(oldId, scope.id);
  };

  const handleNewScope = () => {
    if (startOperation("ADD_SCOPE")) {
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewScopeName("");
    setError("");
    endOperation();
  };

  const handleEditToggle = () => {
    if (isEditing) {
      setIsEditing(false);
      endOperation();
    } else {
      if (startOperation("EDIT_MEMBERS")) {
        setIsEditing(true);
      }
    }
  };

  const handleAddMemberOpen = () => {
    setNewMembers([""]);
    setOpenAddDialog(true);
  };

  const handleAddMemberClose = () => {
    setNewMembers([]);
    setOpenAddDialog(false);
  };

  const handleMemberChange = (index, value) => {
    const updatedMembers = [...newMembers];
    updatedMembers[index] = value;
    setNewMembers(updatedMembers);
  };

  const handleRemoveMember = (index) => {
    const updatedMembers = [newMembers.filter((_, i) => i !== index)];
    setNewMembers(updatedMembers);
  };

  const handleAddMembers = async () => {
    setNewMembers([...newMembers]);
    console.log("New members to add:", newMembers);
    const resp = await inviteMember(apiCall, selectedScope.id, newMembers);
    console.log(resp);
    setOpenAddDialog(false);
    handleScopeInfo(selectedScope.id);
  };

  const handleDeleteMember = async (email) => {
    const resp = await deleteScopeMembership(apiCall, selectedScope.id, email);
    if (resp) {
      successToast("Deleted");
    } else {
      errorToast("Error!");
    }
    handleScopeInfo(selectedScope.id);
  };

  const handleExecutionEnvChange = async (newEnv) => {
    if (!developerEmail) {
      errorToast("Error!");
    }
    let env = newEnv;
    if (env === "LOCAL") {
      env = env + "-" + developerEmail;
    }
    const resp = await saveScopeExecutionEnv(apiCall, selectedScope.id, env);
    if (resp) {
      successToast("Updated!");
    } else {
      errorToast("Error!");
    }
    handleScopeInfo(selectedScope.id);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="start"
          flexDirection="column"
          minHeight="100%"
          p={2}
          width="100%"
        >
          <Box
            position="static"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {isSmallScreen && (
                <IconButton
                  color="inherit"
                  edge="start"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    if (isSmallScreen) {
                      history.goBack();
                    } else {
                      history.replace("/setting");
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}

              <Box sx={{ display: "flex", alignItems: "center" }}>
                {selectedScope && (
                  <Select
                    value={selectedScope}
                    onChange={handleScopeChange}
                    variant="standard"
                    disableUnderline
                    size="small"
                    sx={{
                      color: "inherit",
                      fontSize: "1.25rem",
                      textAlign: "center",
                      padding: 0,
                      minWidth: 0,
                      ".MuiSelect-select": {
                        padding: 0,
                      },
                    }}
                    renderValue={(selected) => `${selected.name}`}
                  >
                    {scopes.map((scope) => (
                      <MenuItem
                        key={scope.id}
                        value={scope.name}
                        sx={{ fontSize: "1.25rem" }}
                      >
                        {scope.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
            </Box>

            <Box>
              <IconButton color="inherit" onClick={handleNewScope}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>

          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogContent>
              <DialogTitle sx={{ p: 0, mb: 4 }}>Add Scope</DialogTitle>
              <TextField
                label="Scope Name"
                fullWidth
                variant="outlined"
                value={newScopeName}
                onChange={(e) => {
                  setNewScopeName(e.target.value);
                  checkDuplicateName(e.target.value);
                }}
                error={!!error}
                helperText={error}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>cancel</Button>
              <Button onClick={handleAddScope}>add</Button>
            </DialogActions>
          </Dialog>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  p: 2,
                  width: "100%",
                  backgroundColor: theme.palette.background.card,
                  fontFamily: "Roboto, sans-serif",
                  borderRadius: "12px",
                  border: "1px solid #EAEEF0",
                  boxShadow: "none",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>Members</Typography>

                  <IconButton
                    sx={{
                      color: theme.palette.text.primary,
                      opacity:
                        currentOperation && currentOperation !== "EDIT_MEMBERS"
                          ? 0.5
                          : 1,
                      pointerEvents:
                        currentOperation && currentOperation !== "EDIT_MEMBERS"
                          ? "none"
                          : "auto",
                    }}
                    onClick={handleEditToggle}
                    disabled={
                      currentOperation && currentOperation !== "EDIT_MEMBERS"
                    }
                  >
                    {isEditing ? <EditOffIcon /> : <EditIcon />}
                  </IconButton>
                </Box>
                <Divider />
                <List>
                  {familyMembers.map((member) => (
                    <ListItem
                      key={member.id}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <ListItemAvatar>
                        <Avatar>{member.email.charAt(0)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={member.email}
                        secondary={
                          <>
                            <Typography>{member.role}</Typography>
                          </>
                        }
                      />

                      {isEditing ? (
                        <>
                          {!member.role === "OWNER" && (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                handleDeleteMember(member.email);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </>
                      ) : !member.pending ? null : (
                        <Typography color="text.secondary">
                          Pending...
                        </Typography>
                      )}
                    </ListItem>
                  ))}
                </List>
                {isEditing && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <IconButton
                      onClick={handleAddMemberOpen}
                      color="primary"
                      sx={{
                        borderRadius: "50%",
                        padding: 2,
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                    <Typography onClick={handleAddMemberOpen} color="primary">
                      add members
                    </Typography>
                  </Box>
                )}
              </Card>
            </Grid>

            {developerEmail && (
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    p: 2,
                    width: "100%",
                    backgroundColor: theme.palette.background.card,
                    fontFamily: "Roboto, sans-serif",
                    borderRadius: "12px",
                    border: "1px solid #EAEEF0",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1">
                        Execution Environment
                      </Typography>
                      <Tooltip title="Select execution environment">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <ToggleButtonGroup
                      value={selectedEnv}
                      exclusive
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          console.log(newValue);
                          handleExecutionEnvChange(newValue);
                        }
                      }}
                      size="small"
                    >
                      <ToggleButton value="PROD">Prod</ToggleButton>
                      <ToggleButton value="STAGING">Staging</ToggleButton>
                      <ToggleButton value="LOCAL">Local</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Card>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  p: 2,
                  width: "100%",
                  backgroundColor: theme.palette.background.card,
                  fontFamily: "Roboto, sans-serif",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItem: "center",
                  borderRadius: "12px",
                  border: "1px solid #EAEEF0",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {isScopeEditing ? (
                    <TextField
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      size="small"
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  ) : (
                    <Typography>{selectedScope.name}</Typography>
                  )}
                </Box>
                <Box>
                  {isScopeEditing ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        ml: 1,
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleSaveEditScope}
                        sx={{ mr: 1 }}
                      >
                        <Typography>Save</Typography>
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleCancelEditScope}
                      >
                        <Typography>Cancel</Typography>
                      </Button>
                    </Box>
                  ) : (
                    <>
                      {isOwner ? (
                        <Button
                          variant="contained"
                          onClick={handleEditScope}
                          sx={{
                            mr: 1,
                            opacity: currentOperation ? 0.5 : 1,
                            pointerEvents: currentOperation ? "none" : "auto",
                          }}
                          disabled={currentOperation}
                        >
                          <Typography>Edit</Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={handleExitScope}
                          sx={{
                            mr: 1,
                            opacity: currentOperation ? 0.5 : 1,
                            pointerEvents: currentOperation ? "none" : "auto",
                          }}
                          disabled={currentOperation}
                        >
                          <Typography>Exit</Typography>
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        onClick={handleDeleteScope}
                        sx={{
                          opacity: currentOperation ? 0.5 : 1,
                          pointerEvents: currentOperation ? "none" : "auto",
                        }}
                        disabled={currentOperation}
                      >
                        <Typography>Delete</Typography>
                      </Button>
                    </>
                  )}
                </Box>
              </Card>
            </Grid>

            <Dialog open={openAddDialog} onClose={handleAddMemberClose}>
              <DialogTitle sx={{ mb: 1 }}>Add Members</DialogTitle>
              <DialogContent>
                {newMembers.map((member, index) => (
                  <Box key={index} sx={{ mb: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Typography variant="subtitle1" sx={{ mr: 2 }}>
                        Member {index + 1}
                      </Typography>
                      <IconButton
                        onClick={() => handleRemoveMember(index)}
                        color="error"
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Box>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                      value={member}
                      onChange={(e) =>
                        handleMemberChange(index, e.target.value)
                      }
                      error={!member}
                      sx={{ mb: 2 }}
                    />
                  </Box>
                ))}
                <Button
                  onClick={() => setNewMembers([...newMembers, ""])}
                  fullWidth
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  Add More Members
                </Button>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddMemberClose}>Cancel</Button>
                <Button
                  onClick={handleAddMembers}
                  color="primary"
                  disabled={newMembers.some((member) => !member)}
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Scope;
