import React, { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { originalPathState } from "../global-state";
import { useHistory, useLocation } from "react-router-dom";
import { Card, IconButton, useMediaQuery } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useTheme } from "@mui/material/styles";
import { checkEmailExists, saveAuthRelay } from "./user-login-service";
import useApiCall from "../common/api-call";

async function signIn(email, password) {
  try {
    const user = await Auth.signIn(email, password);
    console.log("Login successful:", user);
  } catch (error) {
    console.error("Error signing in:", error);
  }
}

const UserLogin = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showResetFields, setShowResetFields] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { apiCall } = useApiCall();
  const [countdown, setCountdown] = useState(0);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const [validations, setValidations] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasMinLength: false,
    noLeadingTrailingSpace: false,
  });

  useEffect(() => {
    Amplify.configure(USER_AUTH_CONFIG);
  }, []);

  const validatePassword = (pwd) => {
    setValidations({
      hasLowerCase: /[a-z]/.test(pwd),
      hasUpperCase: /[A-Z]/.test(pwd),
      hasNumber: /\d/.test(pwd),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<> ]/.test(pwd),
      hasMinLength: pwd.length >= 8,
      noLeadingTrailingSpace: pwd.trim().length === pwd.length,
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const allValid = Object.values(validations).every(Boolean);

  const startCountdown = () => {
    setCountdown(60);
    setIsCountdownActive(true);

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          setIsCountdownActive(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSignIn = async () => {
    setErrorMessage(null);
    try {
      if (!email) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }

      if (!password) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }

      if (!email || !password) {
        return;
      }

      const user = await Auth.signIn(email, password);

      if (source === "app") {
        const idToken = user.signInUserSession.idToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.token;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const expirationTime =
          user.signInUserSession.idToken.payload.exp * 1000;

        // Create a JSON object for the AuthState
        const authState = {
          idToken,
          refreshToken,
          accessToken,
          expirationTime,
        };

        const resp = await saveAuthRelay(apiCall, authState);
        if (resp.data) {
          window.location.href = `mhomeai://auth/login?id=${resp.data}`;
        } else {
          setErrorMessage("Unexpected Error! Try again later.");
        }
      } else {
        history.push(originalPath || "/");
      }
    } catch (error) {
      setErrorMessage("Failed to sign in. Invalid username or password.");
    }
  };

  const handleForgotPassword = async () => {
    try {
      if (!email) {
        setErrorMessage("Please enter the email.");
        return;
      }
      await Auth.forgotPassword(email);
      setShowResetFields(true);
      setNewPassword("");
      setResetCode("");
      setInfoMessage("Password reset code sent to your email.");
    } catch (error) {
      setErrorMessage("Failed to send reset code. Please try again.");
    }
  };

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, resetCode, newPassword);
      setInfoMessage("Password reset successful! You can now sign in.");
      setShowResetFields(false);
      setActiveTab(0); // Switch to SignIn tab after reset
    } catch (error) {
      setErrorMessage("Failed to reset password. Please check your code.");
    }
  };

  const handleGoogleSignIn = async () => {
    console.log("Initiating Google federated sign-in...");
    const response = await Auth.federatedSignIn({ provider: "Google" });
    console.log("Google sign-in successful, fetching user...");
  };

  const handleSignUp = async () => {
    setErrorMessage("");
    setInfoMessage("");
    try {
      if (!email) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }

      if (!password) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }

      if (!email || !password) {
        return;
      }

      const emailExists = await checkEmailExists(apiCall, email);

      if (emailExists) {
        try {
          // Try to sign in to check user status
          await Auth.signIn(email, password);
          // If successful sign in, user exists and is verified
          setErrorMessage(
            "The email address has been registered. Please try to log in or use another email address."
          );
          return;
        } catch (error) {
          if (error.code === "UserNotConfirmedException") {
            // User exists but is not verified, resend the code
            await Auth.resendSignUp(email);
            setShowConfirm(true);
            setInfoMessage(
              "Verification code sent to your email. Valid within five minutes"
            );
            setCodeSent(true);
            startCountdown();
            return;
          } else if (error.code !== "UserNotFoundException") {
            // If error is not UserNotFoundException, user exists
            setErrorMessage(
              "The email address has been registered. Please try to log in or use another email address."
            );
            return;
          }
        }
      }

      console.log("Starting manual registration for email:", email);
      await Auth.signUp({
        username: email,
        password,
        attributes: { email },
      });
      console.log("Sign up successful, verification code sent.");
      setInfoMessage(
        "Verification code sent to your email.Valid within five minutes"
      );
      setShowConfirm(true);
      setCodeSent(true);
      startCountdown();
    } catch (error) {
      console.error("Error during sign up:", error);
      setErrorMessage("Error signing up. Please try again.");
    }
  };

  const handleResendCode = async () => {
    try {
      console.log("Resending verification code to:", email);
      await Auth.resendSignUp(email);
      console.log("Verification code resent successfully");
      setInfoMessage(
        "New verification code sent to your email. Valid within five minutes"
      );
      setShowConfirm(true);
      startCountdown();
    } catch (error) {
      console.error("Error resending verification code:", error);
      setErrorMessage("Failed to resend verification code. Please try again.");
    }
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePassword(value);
  };

  const handleConfirmSignUp = async () => {
    try {
      await Auth.confirmSignUp(email, verificationCode);
      setInfoMessage("Sign up successful! You can now sign in.");
      setShowConfirm(false);
      handleTabSwitch(0);
    } catch (error) {
      setErrorMessage("Invalid verification code. Please try again.");
    }
  };

  const handleTabSwitch = (tab) => {
    setErrorMessage("");
    setInfoMessage("");
    setActiveTab(tab);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: isMobile
          ? theme.palette.background.white
          : theme.palette.background.default,
      }}
    >
      <Card
        elevation={0}
        sx={{
          width: { xs: "100%", sm: "90%", md: "800px", lg: "1000px" },
          height: isMobile ? "auto" : "600px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          boxShadow: 0,
          borderRadius: "12px",
          bgcolor: isMobile ? "transparent" : "background.white",
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: `${theme.palette.background.log2}`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                color: "text.white",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Automation <br />
              now at your voice
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            height: "100%",
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            bgcolor: isMobile ? "transparent" : "background.white",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            component="img"
            src="/logo192.png"
            alt="Company Logo"
            sx={{
              maxWidth: "64px",
              width: "100%",
              height: "auto",
              display: "block",
              margin: "0 auto",
            }}
          />

          <Typography
            variant="h4"
            sx={{ pt: password && activeTab === 1 && !isMobile ? 15 : 4 }}
            align="center"
          >
            Welcome
          </Typography>

          {errorMessage && (
            <Typography color="error" align="center">
              {errorMessage}
            </Typography>
          )}
          {infoMessage && (
            <Typography color="primary" align="center">
              {infoMessage}
            </Typography>
          )}

          <Box sx={{ mt: 2 }}>
            {!showResetFields && (
              <>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setEmailError(false)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      "& input": {
                        p: 1.5,
                      },
                      ...(emailError && {
                        borderColor: "red",
                        "&:hover fieldset": {
                          borderColor: "red",
                        },
                      }),
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1",
                    },
                  }}
                  error={emailError}
                />
                {activeTab == 0 && (
                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => setPasswordError(false)}
                    sx={{
                      mt: 2,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        "& input": {
                          p: 1.5,
                        },
                        ...(emailError && {
                          borderColor: "red",
                          "&:hover fieldset": {
                            borderColor: "red",
                          },
                        }),
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1",
                      },
                    }}
                    error={passwordError}
                  />
                )}
              </>
            )}

            {activeTab == 1 && (
              <>
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={password}
                  onChange={(e) => handlePasswordChange(e)}
                  onFocus={() => setPasswordError(false)}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      "& input": {
                        p: 1.5,
                      },
                      ...(emailError && {
                        borderColor: "red",
                        "&:hover fieldset": {
                          borderColor: "red",
                        },
                      }),
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1",
                    },
                  }}
                  error={passwordError}
                />
                {password && (
                  <ul>
                    <li
                      style={{
                        color: validations.hasLowerCase ? "green" : "red",
                      }}
                    >
                      {validations.hasLowerCase ? "✔ " : "× "}Password must
                      contain a lower case letter
                    </li>
                    <li
                      style={{
                        color: validations.hasUpperCase ? "green" : "red",
                      }}
                    >
                      {validations.hasUpperCase ? "✔ " : "× "}Password must
                      contain an upper case letter
                    </li>
                    <li
                      style={{ color: validations.hasNumber ? "green" : "red" }}
                    >
                      {validations.hasNumber ? "✔ " : "× "}Password must contain
                      a number
                    </li>
                    <li
                      style={{
                        color: validations.hasMinLength ? "green" : "red",
                      }}
                    >
                      {validations.hasMinLength ? "✔ " : "× "}Password must
                      contain at least 8 characters
                    </li>
                    <li
                      style={{
                        color: validations.hasSpecialChar ? "green" : "red",
                      }}
                    >
                      {validations.hasSpecialChar ? "✔ " : "× "}Password must
                      contain a special character
                    </li>
                    <li
                      style={{
                        color: validations.noLeadingTrailingSpace
                          ? "green"
                          : "red",
                      }}
                    >
                      {validations.noLeadingTrailingSpace ? "✔ " : "× "}Password
                      must not contain a leading or trailing space
                    </li>
                  </ul>
                )}
              </>
            )}
          </Box>
          <Box sx={{ textAlign: "left" }}>
            {activeTab === 0 && !showResetFields && (
              <Button
                variant="text"
                sx={{ color: "text.l1", textTransform: "none" }}
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </Button>
            )}
          </Box>
          {showResetFields && activeTab == 0 && (
            <>
              <TextField
                label="Reset Code"
                fullWidth
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& input": {
                      p: 1.5,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1",
                  },
                }}
              />
              <TextField
                label="New Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => handleNewPasswordChange(e)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& input": {
                      p: 1.5,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1",
                  },
                }}
              />
              {newPassword && (
                <ul>
                  <li
                    style={{
                      color: validations.hasLowerCase ? "green" : "red",
                    }}
                  >
                    {validations.hasLowerCase ? "✔ " : "× "}Password must
                    contain a lower case letter
                  </li>
                  <li
                    style={{
                      color: validations.hasUpperCase ? "green" : "red",
                    }}
                  >
                    {validations.hasUpperCase ? "✔ " : "× "}Password must
                    contain an upper case letter
                  </li>
                  <li
                    style={{ color: validations.hasNumber ? "green" : "red" }}
                  >
                    {validations.hasNumber ? "✔ " : "× "}Password must contain a
                    number
                  </li>
                  <li
                    style={{
                      color: validations.hasMinLength ? "green" : "red",
                    }}
                  >
                    {validations.hasMinLength ? "✔ " : "× "}Password must
                    contain at least 8 characters
                  </li>
                  <li
                    style={{
                      color: validations.hasSpecialChar ? "green" : "red",
                    }}
                  >
                    {validations.hasSpecialChar ? "✔ " : "× "}Password must
                    contain a special character
                  </li>
                  <li
                    style={{
                      color: validations.noLeadingTrailingSpace
                        ? "green"
                        : "red",
                    }}
                  >
                    {validations.noLeadingTrailingSpace ? "✔ " : "× "}Password
                    must not contain a leading or trailing space
                  </li>
                </ul>
              )}

              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 1 }}
                onClick={() => setShowResetFields(false)}
              >
                Cancel Reset Password
              </Button>
            </>
          )}

          {!showResetFields && (
            <>
              <Box sx={{ textAlign: "center" }}>
                {activeTab == 0 && (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleSignIn();
                      }}
                      fullWidth
                      sx={{
                        borderRadius: "50px",
                        mt: 2,
                        bgcolor:
                          activeTab === 0 ? "primary.main" : "transparent",
                        color: activeTab === 0 ? "white" : "primary.main",
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      Sign In
                    </Button>
                  </>
                )}
                {activeTab == 1 && (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (codeSent) {
                          handleResendCode();
                        } else {
                          handleSignUp();
                        }
                      }}
                      disabled={isCountdownActive}
                      fullWidth
                      sx={{
                        borderRadius: "50px",
                        mt: 3,
                        bgcolor:
                          activeTab === 1 ? "primary.main" : "transparent",
                        color: activeTab === 1 ? "white" : "primary.main",
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {isCountdownActive
                        ? `Resend in ${countdown}s`
                        : "Send Verification Code"}
                    </Button>
                  </Box>
                )}
              </Box>

              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  fullWidth
                  sx={{
                    mt: 2,
                    borderRadius: "50px",
                    textTransform: "none",
                  }}
                  onClick={handleGoogleSignIn}
                >
                  {activeTab === 0
                    ? "Sign in with Google"
                    : "Sign up with Google"}
                </Button>
              </Box>
              {activeTab === 0 && (
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Need an account?&nbsp;{" "}
                    <span
                      style={{
                        color: theme.palette.text.l1,
                        cursor: "pointer",
                      }}
                      onClick={() => handleTabSwitch(1)}
                    >
                      Sign Up
                    </span>
                  </Typography>
                </Box>
              )}
            </>
          )}

          {activeTab === 1 && (
            <Box sx={{ textAlign: "center" }}>
              {showConfirm && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      jusifyContenx: "flex-start",
                    }}
                  >
                    <Button variant="outlined" sx={{ mr: 1 }}>
                      Verification Code :
                    </Button>
                    <TextField
                      type="text"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      sx={{
                        flex: 1,
                        mt: 2.5,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& input": {
                            p: 0.8,
                          },
                        },
                      }}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      borderRadius: "50px",
                      mt: 2,
                      textTransform: "none",
                      color: "text.white",
                    }}
                    onClick={handleConfirmSignUp}
                  >
                    Confirm Sign Up
                  </Button>
                </>
              )}
              <Typography variant="body2" sx={{ mt: 2 }}>
                Already have an account? &nbsp;{" "}
                <span
                  style={{ color: theme.palette.text.l1, cursor: "pointer" }}
                  onClick={() => handleTabSwitch(0)}
                >
                  Sign In
                </span>
              </Typography>
            </Box>
          )}

          {/* Add this after your login form components */}
          <Box
            sx={{
              mt: 3,
              textAlign: "center",
              fontSize: "0.875rem",
              color: "text.secondary",
            }}
          >
            <Typography variant="body2" sx={{ mb: 2 }}>
              By signing {activeTab === 0 ? "in" : "up"}, you agree to our
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="body2"
                onClick={() => window.open("/terms-of-use", "_blank")}
                sx={{
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Terms of Use
              </Typography>
              <Box component="span">•</Box>
              <Typography
                variant="body2"
                onClick={() => window.open("/privacy-policy", "_blank")}
                sx={{
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Privacy Policy
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default UserLogin;
