import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ReplayIcon from "@mui/icons-material/Replay";
import ShareIcon from "@mui/icons-material/Share";
import AutomationChatCard from "./automation-chat-card";
import TextChatCard from "./text-chat-card";
import WaitingChatCard from "./waiting-chat-card";

const AssistantChatCard = React.memo(
  ({ payload, onSave, onReplay, onDelete }) => {
    const handleReplay = async () => {
      onReplay();
    };

    const renderInteractiveButtons = () => {
      return (
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            mt: 0.5,
            opacity: 0.7,
          }}
        >
          <IconButton
            size="small"
            sx={{ padding: "4px" }}
            onClick={handleReplay}
          >
            <ReplayIcon fontSize="small" />
          </IconButton>
          {/*<IconButton size="small" sx={{ padding: "4px" }}>*/}
          {/*  <ThumbUpIcon fontSize="small" />*/}
          {/*</IconButton>*/}
          {/*<IconButton size="small" sx={{ padding: "4px" }}>*/}
          {/*  <ThumbDownIcon fontSize="small" />*/}
          {/*</IconButton>*/}
          {/*{payload.type === "AUTOMATION" && (*/}
          {/*  <IconButton size="small" sx={{ padding: "4px" }}>*/}
          {/*    <ShareIcon fontSize="small" />*/}
          {/*  </IconButton>*/}
          {/*)}*/}
        </Box>
      );
    };

    const renderCard = () => {
      switch (payload.type) {
        case "AUTOMATION":
          return (
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <AutomationChatCard
                payload={payload}
                onSave={onSave}
                onDelete={onDelete}
              />
              {payload.interactive && renderInteractiveButtons()}
            </Box>
          );
        case "TEXT":
        case "WAKE":
        case "LLM_RES":
        case "AGENT_INFO":
          return (
            <Box
              sx={{ width: "80%", display: "flex", flexDirection: "column" }}
            >
              <TextChatCard message={payload.content} data={payload} />
              {payload.interactive && renderInteractiveButtons()}
            </Box>
          );
        case "WAITING":
          return <WaitingChatCard />;
        default:
          return <></>;
      }
    };

    return (
      <Box display="flex" justifyContent="flex-start" mb={1}>
        {renderCard()}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.payload === nextProps.payload;
  }
);

AssistantChatCard.displayName = "AssistantChatCard";

export default AssistantChatCard;
