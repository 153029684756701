export const startCheckout = async (apiCall, productId) => {
  try {
    const req = { productId };
    const resp = await apiCall(
      `/payment/stripe/create-checkout-session`,
      "POST",
      req
    );
    if (resp.error) {
      return null;
    }
    return resp;
  } catch (error) {
    return null;
  }
};

export const getCredits = async (apiCall) => {
  try {
    const resp = await apiCall(`/user/credit`, "GET");
    if (resp.error) {
      return null;
    }
    return resp;
  } catch (error) {
    return null;
  }
};

export const getPayments = async (apiCall) => {
  try {
    const resp = await apiCall(`/user/payment/history`, "GET");
    if (resp.error) {
      return null;
    }
    return resp;
  } catch (error) {
    return null;
  }
};
