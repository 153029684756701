import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContentContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    !["maxContentWidth", "headerSpacing", "sectionSpacing"].includes(prop),
})(
  ({
    theme,
    maxContentWidth = 1000,
    headerSpacing = 6,
    sectionSpacing = 6,
  }) => ({
    maxWidth: maxContentWidth,
    margin: "0 auto",

    // Headers
    "& h1": {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(40),
      fontWeight: 700,
      marginBottom: theme.spacing(headerSpacing),
      color: theme.palette.text.primary,
      letterSpacing: "0.5px",
    },
    "& h2": {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 600,
      marginTop: theme.spacing(sectionSpacing),
      color: theme.palette.text.primary,
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.background.l1}`,
    },

    // Paragraphs
    "& p": {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.8,
      color: theme.palette.text.primary,
      textAlign: "justify",
      "&:first-of-type": {
        fontSize: theme.typography.pxToRem(17.6),
        color: theme.palette.text.gray,
      },
    },

    // Lists
    "& ul, & ol": {
      paddingLeft: theme.spacing(4),
      "& li": {
        color: theme.palette.text.primary,
        lineHeight: 1.7,
        position: "relative",
        "&::marker": {
          color: theme.palette.text.gray,
        },
      },
    },

    // Responsive styles
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(6),
      "& h1": {
        fontSize: theme.typography.pxToRem(32),
      },
      "& h2": {
        fontSize: theme.typography.pxToRem(20),
      },
      "& p": {
        fontSize: theme.typography.pxToRem(15.2),
        "&:first-of-type": {
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
      "& h1": {
        fontSize: theme.typography.pxToRem(28),
      },
    },
  })
);

export const DocStyleContent = ({
  children,
  maxContentWidth,
  headerSpacing,
  sectionSpacing,
  className,
  ...props
}) => {
  return (
    <StyledContentContainer
      maxContentWidth={maxContentWidth}
      headerSpacing={headerSpacing}
      sectionSpacing={sectionSpacing}
      className={className}
      {...props}
    >
      {children}
    </StyledContentContainer>
  );
};

export default DocStyleContent;
