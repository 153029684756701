// IntegrationDetailView.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import {
  connectIntegration,
  disconnectIntegration,
  getIntegration,
  installIntegration,
  listGroups,
  listProviders,
  saveGroup,
  uninstallIntegration,
} from "./integration-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import { useHistory, useLocation } from "react-router-dom";
import EditGroupDialog from "./edit-group-dialog";
import ConnectDialog from "../common/connect-dialog";
import Chip from "@mui/material/Chip";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import EntityFilter from "./entity-filter";
import GroupFilter from "./group-filter";
import ProviderFilter from "./provider-filter";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import ProviderDetailDialog from "./provider-detail-dialog";

const IntegrationDetailView = ({ showFilter = false, setShowFilter }) => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const [integrationData, setIntegrationData] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [connectIntegrationName, setConnectIntegrationName] = useState("");
  const [connectInfo, setConnectInfo] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openConnect, setOpenConnect] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(showFilter);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const integrationId = searchParams.get("id");

  useEffect(() => {
    handleLoadIntegration();
  }, [integrationId]);

  const handleLoadIntegration = async () => {
    const resp = await getIntegration(
      apiCall,
      userInfo.activeScope.id,
      integrationId
    );
    if (resp) {
      console.log(resp);
      setIntegrationData(resp);
    } else {
      errorToast("Error!");
    }
  };

  const handleLoadGroups = async () => {
    const resp = await listGroups(
      apiCall,
      userInfo.activeScope.id,
      integrationId
    );
    if (resp) {
      setIntegrationData((prev) => {
        const n = { ...prev, groups: resp.groups };
        return n;
      });
    } else {
      errorToast("Error!");
    }
  };

  const handleLoadProviders = async () => {
    const resp = await listProviders(
      apiCall,
      userInfo.activeScope.id,
      integrationId
    );
    if (resp) {
      setIntegrationData((prev) => {
        const n = { ...prev, providers: resp.providers };
        return n;
      });
    } else {
      errorToast("Error!");
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleEntityClick = (entityId) => {
    // history.push(`/entity?id=${entityId}`);
  };

  const handleEditGroup = (group = null) => {
    setSelectedGroup(group);
    setGroupName(group ? group.name : "");
    setSelectedEntities(
      group ? group.entities.map((e) => `${e.integration}-${e.id}`) || [] : []
    );
    setGroupDialogOpen(true);
  };

  const handleToggleEntity = (entityId) => {
    setSelectedEntities((prev) =>
      prev.includes(entityId)
        ? prev.filter((id) => id !== entityId)
        : [...prev, entityId]
    );
  };

  const handleProviderInstall = async (provider) => {
    if (provider.installType === "CONNECT") {
      await handleConnect(provider.integration);
    } else {
      await handleInstall(provider.integration);
    }
  };

  const handleProviderUninstall = async (provider, connectId) => {
    if (provider.installType === "CONNECT") {
      await handleDisconnect(provider.integration, connectId);
    } else {
      await handleUninstall(provider.integration);
    }
  };

  const handleInstall = async (integration) => {
    const resp = await installIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      successToast("Installed");
      handleLoadIntegration();
      setSelectedProvider(null);
    } else {
      errorToast("Error!");
    }
  };

  const handleUninstall = async (integration) => {
    const resp = await uninstallIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      successToast("Uninstalled");
      handleLoadIntegration();
      setSelectedProvider(null);
    } else {
      errorToast("Error!");
    }
  };

  const handleConnectComplete = async () => {
    successToast("Connected");
    handleLoadIntegration();
    setSelectedProvider(null);
  };

  const handleConnect = async (integration) => {
    const resp = await connectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      setConnectInfo(resp);
      setConnectIntegrationName(integration);
      setOpenConnect(true);
    }
  };

  const handleDisconnect = async (integration, connectId) => {
    const resp = await disconnectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration,
      connectId
    );
    if (resp) {
      successToast("Disconnected");
      handleLoadIntegration();
      setSelectedProvider(null);
    } else {
      errorToast("Error!");
    }
  };

  const handleSaveGroup = async (updatedSelection) => {
    const group = {
      scopeId: userInfo.activeScope.id,
      groupId: null,
      name: groupName,
      integration: integrationId,
      entities: [],
    };
    if (selectedGroup) {
      group.groupId = selectedGroup.groupId;
    }
    updatedSelection.forEach((s) => {
      const integrationId = s.substring(0, s.indexOf("-"));
      const entityId = s.substring(s.indexOf("-") + 1);
      group.entities.push({ id: entityId, integration: integrationId });
    });

    if (!group.name) {
      errorToast("Name is required!");
      return;
    }
    if (group.entities.length === 0) {
      errorToast("No group members are selected!");
      return;
    }

    const resp = await saveGroup(apiCall, group);
    if (resp) {
      if (resp.success) {
        successToast("Saved");
        handleLoadGroups();
        setGroupDialogOpen(false);
        setGroupDialogOpen(false);
        setGroupName("");
        setSelectedEntities([]);
      } else {
        if (resp.error === "BAD_REQUEST")
          errorToast("Error! Use a different group name.");
      }
    } else {
      errorToast("Error!");
    }
  };

  const cardStyle = {
    width: "100%",
    cursor: "pointer",
    borderRadius: 1.5,
    transition: "all 0.2s ease-in-out",
    border: `1px solid ${theme.palette.divider}`,
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "12px",
    },
  };

  useEffect(() => {
    setEntityFilterText("");
    setIsShowFilter(showFilter);
  }, [showFilter]);

  const availableTabs = [];

  const [entityFilterText, setEntityFilterText] = useState("");
  const [entitySortBy, setEntitySortBy] = useState("alphabet");
  if (integrationData?.entityEnabled) {
    const sortedEntities = integrationData.entities
      .filter((a) =>
        a.name.toLowerCase().includes(entityFilterText.toLowerCase())
      )
      .sort((a, b) => {
        if (entitySortBy === "alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return -1;
          if (!aIsLetter && bIsLetter) return 1;
          return aName.localeCompare(bName);
        }
      });
    availableTabs.push({
      label: "Entity",
      content: (
        <Box>
          {isShowFilter && (
            <EntityFilter
              sortBy={entitySortBy}
              setSortBy={setEntitySortBy}
              setFilterText={setEntityFilterText}
              setShowFilter={setShowFilter}
            ></EntityFilter>
          )}
          <Grid container spacing={1.5}>
            {sortedEntities.map((entity) => (
              <Grid item xs={6} sm={4} md={2} lg={2} key={entity.id}>
                <Card
                  elevation={0}
                  sx={{
                    ...cardStyle,
                    height: 90,
                    border: "none",
                    backgroundColor: theme.palette.background.paper,
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                  onClick={() => handleEntityClick(entity.id)}
                >
                  <CardContent
                    sx={{
                      p: "12px !important",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 500,
                        fontSize: "0.875rem",
                        lineHeight: 1.2,
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        height: "2.4em", // Allows for 2 lines of text
                      }}
                    >
                      {entity.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      noWrap
                      sx={{
                        mt: 0.5,
                      }}
                    >
                      {entity.integration}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ),
    });
  }

  const [groupFilterText, setGroupFilterText] = useState("");
  const [groupSortBy, setGroupSortBy] = useState("alphabet");
  if (integrationData?.groupEnabled) {
    const sortedGroups = integrationData.groups
      .filter((a) =>
        a.name.toLowerCase().includes(groupFilterText.toLowerCase())
      )
      .sort((a, b) => {
        if (groupSortBy === "alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return -1;
          if (!aIsLetter && bIsLetter) return 1;
          return aName.localeCompare(bName);
        }
      });
    availableTabs.push({
      label: "Group",
      content: (
        <Box>
          {isShowFilter && (
            <GroupFilter
              sortBy={groupSortBy}
              setSortBy={setGroupSortBy}
              setFilterText={setGroupFilterText}
              setShowFilter={setShowFilter}
            ></GroupFilter>
          )}
          <Grid container spacing={1.5}>
            <Grid item xs={6} sm={4} md={2} lg={2}>
              {" "}
              {/* More items per row */}
              <Card
                elevation={0}
                sx={{
                  ...cardStyle,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 90, // Fixed height for consistency
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
                onClick={() => handleEditGroup()}
              >
                <CardContent sx={{ p: "12px !important", textAlign: "center" }}>
                  <AddIcon sx={{ mb: 0.5 }} />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      lineHeight: 1.2,
                    }}
                  >
                    Create Group
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {sortedGroups.map((group, index) => (
              <Grid item xs={6} sm={4} md={2} lg={2} key={index}>
                <Card
                  elevation={0}
                  sx={{
                    ...cardStyle,
                    height: 90,
                    backgroundColor: theme.palette.background.paper,
                    border: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                  onClick={() => handleEditGroup(group)}
                >
                  <CardContent
                    sx={{
                      p: "12px !important",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{
                        fontWeight: 500,
                        fontSize: "0.875rem",
                        textAlign: "center",
                        lineHeight: 1.2,
                      }}
                    >
                      {group.name || `Group ${index + 1}`}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      noWrap
                      sx={{
                        mt: 0.5,
                        textAlign: "center",
                      }}
                    >
                      {group.entities?.length || 0} entities
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ),
    });
  }

  const [providerFilterText, setProviderFilterText] = useState("");
  const [providerSortBy, setProviderSortBy] = useState("alphabet");
  if (integrationData?.providerEnabled) {
    const sortedProviders = integrationData.providers
      .filter((a) =>
        a.name.toLowerCase().includes(providerFilterText.toLowerCase())
      )
      .sort((a, b) => {
        if (providerSortBy === "alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return -1;
          if (!aIsLetter && bIsLetter) return 1;
          return aName.localeCompare(bName);
        }
      });
    availableTabs.push({
      label: "Provider",
      content: (
        <Box>
          {isShowFilter && (
            <ProviderFilter
              sortBy={providerSortBy}
              setSortBy={setProviderSortBy}
              setFilterText={setProviderFilterText}
              setShowFilter={setShowFilter}
            ></ProviderFilter>
          )}
          <Grid container spacing={1.5}>
            {sortedProviders.map((provider) => (
              <Grid item xs={6} sm={4} md={2} lg={2} key={provider.integration}>
                <Card
                  elevation={0}
                  sx={{
                    ...cardStyle,
                    height: 90,
                    border: "none",
                    backgroundColor: provider.installed
                      ? theme.palette.background.paper
                      : theme.palette.background.disabled,
                  }}
                  onClick={() => {
                    setSelectedProvider(provider);
                  }}
                >
                  <CardContent
                    sx={{
                      p: "12px !important",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        noWrap
                        sx={{
                          fontWeight: 500,
                          fontSize: "0.875rem",
                          lineHeight: 1.2,
                        }}
                      >
                        {provider.name}
                      </Typography>
                      {provider.installed && (
                        <DownloadDoneIcon
                          sx={{
                            fontSize: 16,
                            color: theme.palette.success.main,
                          }}
                        />
                      )}
                    </Box>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      noWrap
                      sx={{ mb: "auto" }}
                    >
                      {provider.org}
                    </Typography>
                    {provider.installed ? (
                      <Typography variant="caption" color="textSecondary">
                        {provider.multiConnect
                          ? `${provider.connects.length} Connection${
                              provider.connects.length !== 1 ? "s" : ""
                            }`
                          : "Connected"}
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="textSecondary">
                        Not Connected
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ),
    });
  }
  availableTabs.push({
    label: "Info",
    content: (
      <>
        {integrationData && integrationData.integration === "basic.time" && (
          <Box sx={{ p: 2 }}>
            <Typography variant="body1" gutterBottom>
              This integration is automatically managed based on your Location
              settings.
            </Typography>

            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body1" color="text.secondary">
                {"• Auto-installed when Location 'Home' is configured"}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {"• Auto-uninstalled when Location 'Home' is not set"}
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/setting#location")}
              sx={{ mt: 2 }}
            >
              Location Settings
            </Button>
          </Box>
        )}
      </>
    ),
  });

  return (
    <Box sx={{ padding: 2, pb: 7 }}>
      {integrationData && (
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h4">{integrationData.name}</Typography>
            {integrationData.installed ? (
              <Chip
                label="Installed"
                color="success"
                icon={<CheckCircleOutlineIcon />}
                sx={{
                  backgroundColor: theme.palette.background.l2,
                  color: "white",
                }}
              />
            ) : (
              <Chip
                label="Not Installed"
                color="error"
                sx={{
                  backgroundColor: theme.palette.background.l4,
                  color: "white",
                }}
              />
            )}
            {!integrationData.providerEnabled &&
              !integrationData.installed &&
              integrationData.integration !== "basic.time" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleInstall(integrationData.integration)}
                >
                  Install
                </Button>
              )}
          </Box>
          <Typography variant="subtitle1" color="textSecondary">
            {integrationData.org}
          </Typography>

          {/* Render the content of the active tab */}
          <Box sx={{ mt: 4 }}>{availableTabs[tabIndex]?.content}</Box>

          {/* Tab Navigation */}
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: theme.palette.background.paper,
              borderTop: 1,
              borderColor: "divider",
              zIndex: 1100,
            }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                minHeight: 48,
                "& .MuiTabs-scroller": {
                  display: "flex",
                  justifyContent: "center",
                },
                "& .MuiTabs-flexContainer": {
                  justifyContent: "center",
                },
                "& .MuiTabs-scrollButtons": {
                  width: 28,
                  backgroundColor: theme.palette.background.paper,
                },
                "& .Mui-selected": {
                  fontWeight: "bold",
                },
              }}
            >
              {availableTabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  sx={{
                    minWidth: 100,
                    minHeight: 48,
                    textTransform: "none",
                  }}
                />
              ))}
            </Tabs>
          </Box>

          {/* Group Edit Dialog */}
          <EditGroupDialog
            open={groupDialogOpen}
            onClose={() => setGroupDialogOpen(false)}
            onSave={handleSaveGroup}
            groupName={groupName}
            setGroupName={setGroupName}
            entities={integrationData.entities}
            selectedEntities={selectedEntities}
            onToggleEntity={handleToggleEntity}
          />
          <ConnectDialog
            open={openConnect}
            integration={connectIntegrationName}
            connectInfo={connectInfo}
            onClose={() => setOpenConnect(false)}
            onSuccess={handleConnectComplete}
            onFailure={() => errorToast("Connect Error!")}
          />
          <ProviderDetailDialog
            open={Boolean(selectedProvider)}
            provider={selectedProvider}
            onClose={() => {
              setSelectedProvider(null);
            }}
            onDisconnect={handleProviderUninstall}
            onNewConnect={handleProviderInstall}
          />
        </>
      )}
    </Box>
  );
};

export default IntegrationDetailView;
