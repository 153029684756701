import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import OAuthFlow from "./oauth";

const ConnectDialog = ({
  open,
  integration,
  connectInfo,
  onClose,
  onSuccess,
  onFailure,
}) => {
  const handleSuccess = () => {
    onClose();
    onSuccess();
  };

  const handleFailure = () => {
    onClose();
    onFailure();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {integration} connects to an external service. You will be redirected to
        the service provider site for authorization.
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {connectInfo && connectInfo.oauthConnect && (
          <OAuthFlow
            oAuthInfo={connectInfo.oauthConnect}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
          ></OAuthFlow>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConnectDialog;
