import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import { originalPathState, userInfoState } from "../global-state";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
  Typography,
  Avatar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import useApiCall from "../common/api-call";
import { getUserInfo } from "./setting-service";
import Scope from "./scope";
import Developer from "./developer";
import Location from "./location";
import Room from "./room";
import About from "./about";
import { Amplify, Auth } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";
import { useToast } from "../common/toast";
import Billing from "./billing";

const Setting = () => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [activeScope, setActiveScope] = useState("");
  const [scopes, setScopes] = useState([]);
  const theme = useTheme();
  const [selectedPage, setSelectedPage] = useState("");
  const [isDefault, setIsDefault] = useState(true);
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  const location = useLocation();
  const { successToast, warningToast, errorToast } = useToast();
  const { apiCall } = useApiCall();

  const isSmallScreen = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (location.hash) {
      setSelectedPage(location.hash.substring(1));
    }
    setIsDefault(!location.hash);
  }, [location.hash]);

  const routes = [
    { hash: "scope", title: "Scope" },
    { hash: "developer", title: "Developer" },
    { hash: "location", title: "Location" },
    { hash: "room", title: "Room" },
    { hash: "billing", title: "Billing" },
    { hash: "about", title: "About" },
  ];

  const handleNavigation = (hash) => {
    setIsDefault(false);
    setSelectedPage(hash);
    console.log(hash);
    if (isSmallScreen) {
      history.replace("/setting");
      history.push(`/setting#${hash}`);
    } else {
      history.replace(`/setting#${hash}`);
    }
  };

  const fetchUserInfo = async () => {
    setOriginalPath(location.pathname + location.search + location.hash);
    const resp = await getUserInfo(apiCall, userInfo);
    if (resp) {
      setUserInfo(resp);
    } else {
      errorToast("Error!");
    }
  };

  const handleUserLogout = async () => {
    setOriginalPath(location.pathname + location.search + location.hash);
    history.push("/user-logout");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 64px)",
        padding: isSmallScreen ? "0" : "16px",
        overflow: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "flex-start",
          gap: 2,
          position: "relative",
        }}
      >
        {/* Sidebar */}
        {(!isSmallScreen || (isSmallScreen && isDefault)) && (
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "35%",
              maxWidth: isSmallScreen ? "100%" : 375,
              minHeight: "80vh",
              height: "100%",
              position: isSmallScreen ? "relative" : "sticky",
              top: 0,
              flexShrink: 0,
              borderRadius: 3,
              p: 2,
              backgroundColor: isSmallScreen
                ? "transparent"
                : theme.palette.background.card,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 2,
                p: 2,
              }}
            >
              <Avatar
                alt="User Name"
                src="/path_to_avatar_image.jpg"
                sx={{ width: 80, height: 80, mt: 3 }}
              />
              <Typography
                variant="h6"
                sx={{
                  mt: 1,
                  color: theme.palette.text.gray,
                }}
              >
                {userInfo.name}
              </Typography>
            </Box>
            <List>
              {routes
                .filter((page) => {
                  return (
                    page.hash !== "developer" || userInfo.setting.engAccount
                  );
                })
                .map((page, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleNavigation(page.hash)}
                    sx={{
                      textAlign: "center",
                      mb: 1,
                      borderRadius: "12px",
                      backgroundColor: theme.palette.background.card,
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    <ListItemText primary={page.title} />
                  </ListItem>
                ))}
              <ListItem
                button
                onClick={() => {
                  handleUserLogout();
                }}
                sx={{
                  textAlign: "center",
                  mb: 1,
                  borderRadius: "12px",
                  backgroundColor: theme.palette.background.card,
                  color: "#FF3B30", // Red text
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "rgba(255, 59, 48, 0.2)",
                  },
                }}
              >
                <ListItemText
                  primary="Sign out"
                  sx={{
                    "& .MuiListItemText-primary": {
                      color: "#FF3B30",
                      fontWeight: 600,
                    },
                  }}
                />
              </ListItem>
            </List>
          </Box>
        )}

        {/* Content */}
        {(!isSmallScreen || (isSmallScreen && !isDefault)) && (
          <Box
            sx={{
              flexGrow: 1,
              height: "100%",
              overflowY: "auto",
              borderRadius: 3,
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              minHeight: "80vh",
              backgroundColor: isSmallScreen
                ? "transparent"
                : theme.palette.background.card,
            }}
          >
            {selectedPage === "scope" && (
              <>
                <Scope />
              </>
            )}
            {selectedPage === "developer" && (
              <>{userInfo.setting.engAccount && <Developer />}</>
            )}
            {selectedPage === "location" && (
              <>
                <Location />
              </>
            )}
            {selectedPage === "room" && (
              <>
                <Room />
              </>
            )}
            {selectedPage === "billing" && (
              <>
                <Billing />
              </>
            )}
            {selectedPage === "about" && (
              <>
                <About />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Setting;
