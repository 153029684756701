import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Divider,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { getHistory } from "./automation-history-service";
import { getHistoryList } from "./automation-history-service";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import theme from "../theme";
import LoadingSpinner from "../common/loading";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const AutomationHistoryList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [data, setData] = useState([]);
  const [groupedHistories, setGroupedHistories] = useState({});
  const [filterDate, setFilterDate] = useState("");
  const [filterType, setFilterType] = useState("day");
  const [expandedHistories, setExpandedHistories] = useState([]);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [loading, setLoading] = useState(true);

  const { apiCall } = useApiCall();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    const grouped = data.reduce((acc, History) => {
      const day = dayjs(History.timestamp).format("YYYY-MM-DD");
      if (!acc[day]) acc[day] = [];
      acc[day].push(History);
      return acc;
    }, {});
    setGroupedHistories(grouped);
  }, [data]);

  const fetchData = async () => {
    let result = id
      ? await getHistory(apiCall, userInfo.activeScope.id, id)
      : await getHistoryList(apiCall, userInfo.activeScope.id);

    if (result instanceof Array) {
      if (result.length == 0) {
        result = {};
      }
    } else if (!result) {
      result = {};
    }

    const histories =
      Object.keys(result).length > 0
        ? id
          ? result.automationHistories.map((history) => {
              history.name = result.name;
              return history;
            })
          : result.automationHistoryEntryList?.flatMap((list) => {
              return list.automationHistories.map((history) => {
                history.name = list.name;
                return history;
              });
            })
        : [];

    const sortedHistories = (histories || []).sort(
      (a, b) => b.timestamp - a.timestamp
    );
    setData(sortedHistories);
  };

  const handleToggleHistory = (index) => {
    setExpandedHistories((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleFilterHistories = async () => {
    if (!filterDate) {
      fetchData();
      return;
    }

    const histories = data || [];

    const filteredHistories = histories.filter((history) => {
      const timestamp = dayjs(history.timestamp);

      if (filterType === "month") return timestamp.isSame(filterDate, "month");
      return timestamp.isSame(filterDate, "day");
    });

    const grouped = filteredHistories.reduce((acc, history) => {
      const day = dayjs(history.timestamp).format("YYYY-MM-DD");
      if (!acc[day]) acc[day] = [];
      acc[day].push(history);
      return acc;
    }, {});
    setGroupedHistories(grouped);
  };

  useEffect(() => {
    handleFilterHistories();
  }, [filterDate]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Box>
              <FormControl variant="outlined" sx={{ mr: 1, width: "100%" }}>
                <InputLabel id="filter-type-label">Filter Type</InputLabel>
                <Select
                  labelId="filter-type-label"
                  id="filter-type-label"
                  label="filter-type-label"
                  value={filterType}
                  onChange={(e) => {
                    setFilterType(e.target.value);
                    setFilterDate("");
                  }}
                  sx={{
                    backgroundColor: theme.palette.background.white,
                  }}
                  fullWidth
                >
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="day">Date</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                ml: 1,
                backgroundColor: theme.palette.background.white,
              }}
            >
              {filterType === "month" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year", "month"]}
                    label="Filter by Month"
                    value={filterDate ? dayjs(filterDate) : null}
                    onChange={(newValue) => {
                      setFilterDate(newValue ? newValue.format("YYYY-MM") : "");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
              {filterType === "day" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Filter by Date"
                    value={filterDate ? dayjs(filterDate) : null}
                    onChange={(newValue) => {
                      setFilterDate(
                        newValue ? newValue.format("YYYY-MM-DD") : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        sx={{ marginTop: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Box>
          </Box>

          {Object.keys(groupedHistories).length > 0 ? (
            Object.keys(groupedHistories).map((day, idx) => (
              <Box key={idx} sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {day}
                </Typography>
                <Divider sx={{ mb: 2 }} />

                {groupedHistories[day].map((history, index) => (
                  <Accordion
                    key={index}
                    expanded={expandedHistories.includes(`${day}-${index}`)}
                    onChange={() => handleToggleHistory(`${day}-${index}`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItem: "center",

                            width: "100%",
                          }}
                        >
                          <Typography variant="body1" fontWeight="bold">
                            Automation - {history.name}
                          </Typography>
                          {history.logs.some(
                            (log) =>
                              log.level.trim().toLowerCase() === "error" ||
                              (log.level.trim().toLowerCase() === "flag" &&
                                log.message.toLowerCase() === "error")
                          ) && (
                            <ErrorIcon
                              sx={{ color: "red", ml: 2 }}
                              fontSize="small"
                              titleAccess="Error"
                            />
                          )}
                        </Box>
                        <Typography variant="caption" color="textSecondary">
                          {customFormat(dayjs(history.timestamp), currentTime)}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {history.logs
                        .filter(
                          (log) => log.level.trim().toLowerCase() !== "flag"
                        )
                        .map((log, index) => (
                          <Typography
                            key={index}
                            sx={{
                              mb: 1,
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                            noWrap={false}
                          >
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                              {log.message}
                            </ReactMarkdown>
                          </Typography>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              No histories available.
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default AutomationHistoryList;
