import React, { useEffect, useState } from "react";
import { Card, CardHeader, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AutomationEditCardContent from "../automation/automation-edit-card-content";

const AutomationChatCard = ({ payload, onSave, onDelete }) => {
  const theme = useTheme();
  const [status, setStatus] = useState("");

  const getInitialStatus = () => {
    if (payload.content && payload.data.automationId) {
      return "updating";
    } else if (payload.content && !payload.data.automationId) {
      return "draft";
    } else if (!payload.content && payload.data.automationId) {
      if (payload.data.finished === "true") {
        return "finished";
      } else {
        return "saved";
      }
    } else {
      return "na";
    }
  };

  const getStatusColor = () => {
    switch (status) {
      case "updating":
        return theme.palette.background.l3;
      case "draft":
        return theme.palette.background.l3;
      case "saved":
        return theme.palette.background.l2;
      case "finished":
        return theme.palette.background.l2;
      case "na":
        return theme.palette.background.l4;
      default:
        return theme.palette.background.l0;
    }
  };

  const getStatusText = () => {
    switch (status) {
      case "updating":
        return "Updating";
      case "draft":
        return "Draft";
      case "saved":
        return "Saved";
      case "finished":
        return "Finished";
      case "na":
        return "Unavailable";
      default:
        return "";
    }
  };

  useEffect(() => {
    setStatus(getInitialStatus());
  }, [payload]);

  const handleSave = (aid) => {
    onSave(payload.data.sessionId, aid);
  };

  const handleDelete = (aid) => {
    onDelete(aid);
  };

  const getParams = () => {
    const p = {
      automationId: payload.data.automationId,
      draft: payload.content,
    };
    return p;
  };

  return (
    <Card
      elevation={0}
      sx={{
        maxWidth: "100%",
        backgroundColor: theme.palette.background.card,
        fontFamily: "Roboto, sans-serif",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "8px",
      }}
    >
      <CardHeader
        title={<Typography variant="h6">{getStatusText()}</Typography>}
        sx={{
          backgroundColor: getStatusColor(),
          color: theme.palette.text.black,
        }}
      />
      {status && status !== "na" && (
        <AutomationEditCardContent
          params={getParams()}
          editable={
            status === "updating" || status === "saved" || status === "draft"
          }
          onSave={handleSave}
          onDelete={handleDelete}
        />
      )}
    </Card>
  );
};

export default AutomationChatCard;
