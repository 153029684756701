import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { userIdTokenState, userInfoState } from "../global-state";
import { useAuthCheck } from "../auth/auth-check";
import useApiCall from "../common/api-call";

const WaitlistPage = () => {
  const { userAuthState, setUserAuthState, checkAuthState } = useAuthCheck();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiCall } = useApiCall();

  useEffect(() => {
    checkAuthState();
  }, []);

  const handleJoinWaitlist = async () => {
    try {
      setIsLoading(true);

      const response = await apiCall("/waitlist/join", "POST", "");

      if (response.success) {
        // Update user info to reflect waitlist status
        setUserInfo((prev) => ({
          ...prev,
          toWait: false,
          inWait: true,
        }));
      } else {
        // Handle error scenario
        setError(response.message || "Something went wrong. Please try again.");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Render loading state
  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // Render error state
  if (error) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Alert severity="error">{error}</Alert>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => setError(null)}
          >
            Try Again
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        {/* Condition 1: User is eligible to join waitlist */}
        {userInfo.toWait && (
          <>
            <Typography variant="h4" gutterBottom>
              Join Our Waitlist
            </Typography>

            <Typography variant="body1" paragraph>
              Be the first to know when we launch!
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleJoinWaitlist}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Join Waitlist"}
            </Button>
          </>
        )}

        {/* Condition 2: User is already in waitlist */}
        {userInfo.inWait && (
          <>
            <Typography variant="h4" gutterBottom>
              You&apos;re on the Waitlist
            </Typography>

            <Typography variant="body1" paragraph>
              Thanks for your interest! We&apos;ll onboard you very soon.
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default WaitlistPage;
