import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import debounce from "lodash/debounce";
import Grid from "@mui/material/Grid";
import { searchableSelectCheck } from "./searchable-select-check";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

/**
 * {value: string, enums: Array<string>, ids: Array<string>}
 */
const SearchableSelectInput = ({
  valueSpec,
  onChange,
  onError,
  multiSelect = false,
  fetchValueSpec,
}) => {
  const [open, setOpen] = useState(false);
  const [specData, setSpecData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [selectedValue, setSelectedValue] = useState(multiSelect ? [] : "");
  const [error, setError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (valueSpec) {
      setLoading(true);
      setSelectedValue(valueSpec.value);
      setSpecData(valueSpec);
      refreshValueSpec(inputText);
      const err = checkValue(valueSpec.value, valueSpec);
      if (err) {
        setError(true);
        onError(true);
      }
    }
    return () => {};
  }, [valueSpec]);

  const debouncedRefreshValueSpec = useCallback(
    debounce((currentInput) => {
      refreshValueSpec(currentInput);
    }, 1000),
    []
  );

  const handleInputChange = (event, newValue) => {
    setInputText(newValue);
    if (fetchValueSpec) {
      debouncedRefreshValueSpec(newValue);
    }
  };

  const refreshValueSpec = (inputText) => {
    setLoading(true);
    if (fetchValueSpec) {
      console.log(inputText);
      fetchValueSpec(inputText)
        .then((vs) => {
          const input = vs.value;
          if (input === inputText) {
            setSpecData(vs.valueSpec);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching options:", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleSelectChange = (event, newValue) => {
    const err = checkValue(newValue, specData);
    setSelectedValue(newValue);
    setError(err);
    if (specData && specData.ids && specData.enums) {
      if (multiSelect) {
        const selectedIds = newValue.map(
          (val) => specData.ids[specData.enums.indexOf(val)]
        );
        onChange(newValue, selectedIds);
      } else {
        const selectedId = specData.ids[specData.enums.indexOf(newValue)];
        onChange(newValue, selectedId);
      }
    } else {
      onChange(newValue);
    }
    onError(err);
  };

  const handleBlur = (event, newVal) => {
    // checkValue(inputText, specData);
  };

  const checkValue = (value, spec) => {
    if (multiSelect) {
      for (let i = 0; i < value.length; i++) {
        const err = !searchableSelectCheck(value[i], spec);
        if (err) {
          return err;
        }
      }
      return false;
    } else {
      const err = !searchableSelectCheck(value, spec);
      return err;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pt: "8px",
      }}
    >
      {valueSpec && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: 1,
          }}
        >
          {valueSpec.name && (
            <Typography
              variant="h6"
              sx={{
                flexShrink: 0, // Prevent typography from shrinking
                mb: 1,
              }}
            >
              {valueSpec.name}
            </Typography>
          )}
          <Box
            sx={{
              flex: 1, // Take remaining space
              minHeight: 0, // Allow container to shrink
              position: "relative",
            }}
          >
            <Autocomplete
              multiple={multiSelect}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              options={specData ? specData.enums || [] : []}
              loading={loading}
              inputValue={inputText}
              onInputChange={handleInputChange}
              onChange={handleSelectChange}
              onBlur={handleBlur}
              value={selectedValue}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      {...tagProps}
                      key={key}
                      label={option}
                      style={{
                        backgroundColor: theme.palette.background.l1,
                        color: theme.palette.text.l1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                  );
                })
              }
              sx={{
                height: "100%",
                "& .MuiAutocomplete-root": {
                  height: "100%",
                },
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  alignItems: "flex-start",
                  padding: "4px 8px",
                },
                "& .MuiAutocomplete-endAdornment": {
                  top: "50%",
                  transform: "translateY(-50%)",
                },
                "& .MuiAutocomplete-inputRoot": {
                  height: "100%",
                  flexWrap: "wrap",
                  overflow: "auto",
                  "& .MuiAutocomplete-input": {
                    height: "auto",
                  },
                },
              }}
              noOptionsText={loading ? "Loading..." : "No options"}
              loadingText={
                <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                  <CircularProgress size={20} />
                </Box>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select value"
                  variant="outlined"
                  error={error}
                  sx={{
                    height: "100%",
                    "& .MuiInputBase-root": {
                      height: "100%",
                      alignItems: "flex-start",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 1,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SearchableSelectInput;
