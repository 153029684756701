import { useRecoilState } from "recoil";
import { SAND_CAT } from "../config";
import { developerIdTokenState, userIdTokenState } from "../global-state";
import { Amplify, Auth } from "aws-amplify";
import { DEV_AUTH_CONFIG, USER_AUTH_CONFIG } from "../aws-config";
import { useHistory } from "react-router-dom";

const useApiCall = () => {
  const [userIdToken, setUserIdToken] = useRecoilState(userIdTokenState);
  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );
  const history = useHistory();

  const apiCall = async (
    endpoint,
    method,
    body = null,
    role = "user",
    retry = 0,
    oUserToken = null,
    oDevToken = null
  ) => {
    if (retry >= 2) {
      return null;
    }
    try {
      const headers = {};
      const roleMap = {
        user: "Authorization",
        dev: "DeveloperAuthorization",
        org: "OrgAuthorization",
        admin: "AdminAuthorization",
      };

      const roles = role.split(","); // Split the input role string by commas

      const ut = oUserToken ? oUserToken : userIdToken;
      const dt = oDevToken ? oDevToken : developerIdToken;

      for (const r of roles) {
        if (r in roleMap) {
          const token = r === "user" ? ut : dt;
          if (token) {
            headers[roleMap[r]] = `Bearer ${token || ""}`;
          } else {
            headers[roleMap[r]] = "";
          }
        }
      }

      const options = {
        method,
        headers,
      };

      if (body instanceof FormData) {
        options.body = body;
      } else if (body) {
        options.body = JSON.stringify(body);
        headers["Content-Type"] = "application/json;charset=UTF-8";
      }

      // Check if the current domain is the development environment
      let baseUrl = SAND_CAT;
      const customAddress = localStorage.getItem("localServiceAddress");
      if (customAddress) {
        // Replace "localhost" with the custom address
        baseUrl = SAND_CAT.replace("localhost", customAddress);
      }

      const response = await fetch(`${baseUrl}${endpoint}`, options);
      const jsonData = await response.json();

      if (!response.ok) {
        throw new Error(jsonData);
      }

      if (jsonData.error) {
        if (jsonData.error === "UNAUTHENTICATED") {
          const ut = await refreshUserToken();
          const dt = await refreshDeveloperToken();
          return await apiCall(endpoint, method, body, role, retry + 1, ut, dt);
        }
      }

      return jsonData;
    } catch (error) {
      console.error(`Error during ${method} request to ${endpoint}:`, error);
      return null;
    }
  };

  const refreshUserToken = async () => {
    try {
      Amplify.configure(USER_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {
      console.error("Error to refresh user token");
    }
  };

  const refreshDeveloperToken = async () => {
    try {
      Amplify.configure(DEV_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      setDeveloperIdToken(idToken);
      return idToken;
    } catch (error) {
      // do nothing for developer not login
    }
  };

  return { apiCall };
};

export default useApiCall;
