import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Autocomplete,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import { debounce } from "lodash";
import { searchLocation } from "./location-service";
import useApiCall from "../common/api-call";

const LocationDialog = ({
  open,
  onClose,
  onSave,
  onDelete,
  initialData = null,
  locationNames = [],
}) => {
  const [locationType, setLocationType] = useState("home");
  const [locationName, setLocationName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const { apiCall } = useApiCall();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (initialData) {
      setLocationName(initialData.name);
      if (initialData.locationId.toLowerCase() === "home") {
        setLocationType("home");
      } else {
        setLocationType("other");
      }
      setSelectedAddress({
        address: initialData.address,
        latitude: initialData.latitude,
        longitude: initialData.longitude,
        timezone: initialData.timezone,
        locationId: initialData.locationId,
      });
    } else {
      setLocationType("home");
      setLocationName("Home");
      setSelectedAddress(null);
    }
  }, [initialData, open]);

  const searchLocations = async (query) => {
    if (!query) {
      setOptions([]);
      return;
    }

    setLoading(true);
    const resp = await searchLocation(apiCall, query);
    if (resp) {
      setOptions(resp);
    } else {
      setOptions([]);
    }
    setLoading(false);
  };

  const debouncedSearch = useCallback(debounce(searchLocations, 500), []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleLocationTypeChange = (event) => {
    const newType = event.target.value;
    setLocationType(newType);
    if (newType === "home") {
      setLocationName("Home");
    } else {
      setLocationName("");
    }
  };

  const handleNameChaneg = (e) => {
    const value = e.target.value;
    setLocationName(value);

    if (value.trim().toLocaleLowerCase() === "home") {
      setError("Location name cannot be 'Home' for type 'Other'");
    } else if (locationNames !== []) {
      if (
        locationNames.some((name) => {
          return name.trim().toLowerCase() === value.trim().toLowerCase();
        })
      ) {
        setError("Location name already exists.");
      } else {
        setError("");
      }
    }
  };

  const handleSave = () => {
    setError("");
    if (!locationName || !selectedAddress) return;

    onSave({
      name: locationName,
      address: selectedAddress.address,
      latitude: selectedAddress.latitude,
      longitude: selectedAddress.longitude,
      timezone: selectedAddress.timezone,
      locationId:
        locationType === "home" ? "home" : selectedAddress.locationId || null,
    });
    onClose();
    setSelectedAddress(null);
  };

  const handleDelete = () => {
    if (initialData && onDelete) {
      onDelete(initialData);
      onClose();
    }
  };

  const handleDeleteClick = () => {
    if (locationType === "home") {
      setConfirmDeleteOpen(true);
    } else {
      handleDelete();
    }
  };

  const handleDeleteConfirm = () => {
    handleDelete();
    setConfirmDeleteOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Location</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={locationType}
                onChange={handleLocationTypeChange}
              >
                <FormControlLabel
                  value="home"
                  control={<Radio />}
                  label="Home"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>

            {locationType === "other" && (
              <TextField
                label="Location Name"
                value={locationName}
                onChange={(e) => handleNameChaneg(e)}
                fullWidth
                required
                error={!!error}
                helperText={error}
              />
            )}

            <Autocomplete
              freeSolo
              options={options}
              getOptionLabel={(option) => option.address || ""}
              value={selectedAddress}
              onChange={(event, newValue) => setSelectedAddress(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Address"
                  onChange={handleSearchChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading && <CircularProgress size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Box>
            {initialData && (
              <Button
                onClick={handleDeleteClick}
                color="error"
                variant="outlined"
              >
                Delete
              </Button>
            )}
          </Box>
          <Box>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={
                !locationName ||
                !selectedAddress ||
                (locationType === "other" && !locationName.trim()) ||
                error
              }
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* confirm delete */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <Box>
            Removing location &apos;Home&apos; will disable the time & schedule
            related capabilities. Do you want to continue?
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LocationDialog;
