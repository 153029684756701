import dayjs from "dayjs";

export const customFormat = (timestamp, now) => {
  const diffMinutes = now.diff(timestamp, "minute");
  const diffHours = now.diff(timestamp, "hour");

  if (diffMinutes === 0) {
    return "Just now";
  } else if (diffMinutes > 0 && diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  } else if (diffHours > 0 && diffHours < 24) {
    return `${diffHours} hours ago`;
  } else {
    return dayjs(timestamp).format("YYYY-MM-DD HH:mm");
  }
};
