import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Container } from "@mui/material";
import { useHistory } from "react-router-dom";
import useApiCall from "../common/api-call";

const PaymentSuccess = () => {
  const history = useHistory();
  const { apiCall } = useApiCall();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        if (!sessionId) {
          throw new Error();
        }
        const resp = await apiCall(
          `/user/payment/check?provider=stripe&paymentId=${sessionId}`,
          "GET"
        );
        if (resp.success) {
          history.push("/");
          history.push("/setting#billing");
          return;
        }

        setTimeout(checkPaymentStatus, 2000);
      } catch (error) {
        console.error("Error checking payment status:", error);
        setError("Something went wrong. Please check payment history.");
        setTimeout(() => {
          history.push("/");
          history.push("/setting#billing");
        }, 3000);
      }
    };

    checkPaymentStatus();
  }, [sessionId, history]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: 3,
        }}
      >
        {error ? (
          <Typography color="error" variant="h6">
            {error}
          </Typography>
        ) : (
          <>
            <CircularProgress size={50} />
            <Typography variant="h6">Processing payment...</Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
