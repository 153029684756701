import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Snackbar, Typography } from "@mui/material";
import FixedSelectInput from "../input/fixed-select-input";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { useWebSocket } from "../ws/websocket";
import { replyAutomationDialog } from "../automation/automation-service";
import { useEventEmitter } from "../ws/event-context";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import NotificationDialogList from "./notification-dialog-list";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import { customFormat } from "../common/custom-format";
import { useMediaQuery } from "@mui/material";

dayjs.extend(relativeTime);
dayjs.extend(calendar);

const getSelector = (dialog) => {
  if (dialog.select) {
    return "FIXED_SELECT";
  } else {
    throw new Error("unsupported");
  }
};

const getNeedConfirm = (dialog) => {
  if (dialog.select) {
    return false;
  } else {
    return true;
  }
};

// const checkerMap = {
//     DYNAMIC_SELECT: searchableSelectCheck,
//     FIXED_SELECT: fixedSelectCheck,
//     NUMBER: numberCheck,
//     NUMBER_UNIT: numberUnitCheck,
//     TIME: timeCheck,
//     DATE: dateCheck,
//     TEXT: textCheck,
// };

const getValueSpec = (dialog) => {
  const spec = {};
  if (dialog.select) {
    spec.enums = dialog.select.options;
  }
  return spec;
};

const updateDialogValue = (dialog, value) => {
  if (dialog.select) {
    dialog.select.value = value;
  }
};

const notificationPredicate = (a, b) =>
  a &&
  b &&
  a.title === b.title &&
  a.message === b.message &&
  a.timestamp === b.timestamp;

const dialogPredicate = (a, b) => a && b && a.dialogId === b.dialogId;

const DataSelector = ({ dialog, onConfirm, onIgnore }) => {
  const selectMap = {
    FIXED_SELECT: (f) => (
      <FixedSelectInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, value)}
        onError={(error) => handleErrorChange(error)}
        useBiToggle
      ></FixedSelectInput>
    ),
  };

  const [selector, setSelector] = useState(null);
  const [needConfirm, setNeedConfirm] = useState(false);
  const [valueSpec, setValueSpec] = useState(null);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setSelector(getSelector(dialog));
    setNeedConfirm(getNeedConfirm(dialog));
    setValueSpec(getValueSpec(dialog));
  }, []);

  const handleValueChange = async (vSpec, newValue) => {
    setValue(newValue);
    vSpec.value = newValue;
    if (!needConfirm) {
      await onConfirm(dialog, newValue);
    }
  };

  const handleErrorChange = (err) => {
    setError(err);
  };

  const handleConfirm = async () => {
    if (error) {
      return;
    }
    await onConfirm(dialog, value);
  };

  const handleIgnore = () => {
    onIgnore(dialog);
  };

  return (
    <Box>
      <Grid container spacing={2} direction="column">
        {selectMap[selector] !== undefined && (
          <Grid item xs={1}>
            <Grid container columns={{ xs: 12 }}>
              <Grid item xs>
                {selectMap[selector](valueSpec)}
              </Grid>
            </Grid>
          </Grid>
        )}
        {needConfirm && (
          <Grid item xs={1}>
            <Grid
              container
              columns={{ xs: 12 }}
              rowSpacing={2}
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <Button
                  size="medium"
                  variant="outlined"
                  fullWidth
                  onClick={handleIgnore}
                >
                  Ignore
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="medium"
                  variant="contained"
                  fullWidth
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const NotificationDialogSystem = ({
  listOpen,
  setListOpen,
  setNotificationStatus,
}) => {
  const [dialogs, setDialogs] = useState(() => {
    const saved = localStorage.getItem("dialogs");
    return saved ? JSON.parse(saved) : [];
  });
  const [dialog, setDialog] = useState(null);
  const [notification, setNotification] = useState(null);
  const [notifications, setNotifications] = useState(() => {
    const savedNotifications = localStorage.getItem("notifications");
    return savedNotifications ? JSON.parse(savedNotifications) : [];
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiCall } = useApiCall();
  const { ws } = useWebSocket();
  const { subscribe } = useEventEmitter();
  const [currentTime, setCurrentTime] = useState(dayjs());
  const theme = useTheme();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "dialogs") {
        setDialogs(JSON.parse(event.newValue || "[]"));
      } else if (event.key === "notifications") {
        setNotifications(JSON.parse(event.newValue || "[]"));
      }
    };

    // window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let pollInterval;
    const pollWebSocket = async () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        clearInterval(pollInterval);
      }
    };
    pollInterval = setInterval(pollWebSocket, 500);

    const { unsubscribe } = subscribe((target, data) => {
      console.log(target, data);
      if (target === "/dialog") {
        const payload = JSON.parse(data.payload);
        receiveDialog(payload);
      } else if (target === "/notification") {
        const payload = JSON.parse(data.payload);
        console.log(payload);
        receiveNotification(payload);
      }
    });

    return () => {
      clearInterval(pollInterval);
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (dialogs.length > 0) {
      setNotificationStatus("d");
    } else if (notifications.length > 0) {
      setNotificationStatus("n");
    } else {
      setNotificationStatus("");
    }

    if (!notifications.some((n) => notificationPredicate(n, notification))) {
      setNotification(null);
    }

    if (!dialogs.some((n) => dialogPredicate(n, dialog))) {
      hideDialog();
    }

    return () => {};
  }, [dialogs, notifications]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const receiveNotification = (payload) => {
    setNotifications((prevNotifications) => {
      const predicate = (item) => notificationPredicate(item, payload);
      const newNotifications = [...prevNotifications];
      if (!newNotifications.some(predicate)) {
        newNotifications.push(payload);
        setNotification(payload);
        setDialog(null);
      }
      localStorage.setItem("notifications", JSON.stringify(newNotifications));
      return newNotifications;
    });
  };

  const receiveDialog = (dialogChanges) => {
    console.log(dialogChanges);
    let newd = [...dialogs];
    console.log(newd);

    for (const d of dialogChanges.changes) {
      if (d.type === "DELETE_DIALOG") {
        newd = newd.filter(
          (d) =>
            d.scopeId !== dialogChanges.scopeId ||
            d.automationId !== d.deleteAutomationId ||
            d.dialogId !== d.deleteDialogId
        );
      } else if (d.type === "DELETE_AUTOMATION") {
        newd = newd.filter(
          (d) =>
            d.scopeId !== dialogChanges.scopeId ||
            d.automationId !== d.deleteAutomationId
        );
      } else if (d.type === "UPSERT") {
        const idx = newd.findIndex(
          (dd) =>
            dd.scopeId === dialogChanges.scopeId &&
            dd.automationId === d.dialog.automationId &&
            dd.dialogId === d.dialog.dialogId
        );
        if (idx !== -1) {
          newd[idx] = d.dialog;
        } else {
          newd.push(d.dialog);
          showDialog(d.dialog);
          setNotification(null);
        }
      }
    }
    setDialogs(newd);
    localStorage.setItem("dialogs", JSON.stringify(newd));
  };

  const clearAllNotifications = () => {
    setNotifications((prevNotifications) => {
      localStorage.setItem("notifications", JSON.stringify([]));
      return [];
    });
    setNotification(null);
  };

  const handleNotificationClick = (notification) => {
    setNotification(notification);
    setNotifications((prevNotifications) => {
      const predicate = (item) => notificationPredicate(item, notification);
      const newNotifications = prevNotifications.filter(
        (item) => !predicate(item)
      );
      localStorage.setItem("notifications", JSON.stringify(newNotifications));
      return newNotifications;
    });
  };

  const handleDialogTimeout = (dialog) => {
    setDialogs((prev) => {
      const predicate = (item) => item.dialogId === dialog.dialogId;
      const newd = prev.filter((item) => !predicate(item));
      localStorage.setItem("dialogs", JSON.stringify(newd));
      return newd;
    });
  };

  const handleDialogClick = (dialog) => {
    showDialog(dialog);
  };

  const showDialog = (dialog) => {
    setDialog(dialog);
    setDrawerOpen(true);
  };

  const hideDialog = () => {
    setDrawerOpen(false);
    setDialog(null);
  };

  const handleDialogConfirm = async (dialog, value) => {
    updateDialogValue(dialog, value);
    await replyAutomationDialog(apiCall, userInfo.activeScope.id, dialog);
    setDrawerOpen(false);
    setDialog(null);

    setDialogs((prevDialogs) => {
      const predicate = (item) => dialogPredicate(item, dialog);
      const newDialogs = prevDialogs.filter((item) => !predicate(item));
      localStorage.setItem("dialogs", JSON.stringify(newDialogs));
      return newDialogs;
    });
  };

  const handleDismissNotification = () => {
    setNotification(null);
    setNotifications((prevNotifications) => {
      const predicate = (item) => notificationPredicate(item, notification);
      const newNotifications = prevNotifications.filter(
        (item) => !predicate(item)
      );
      localStorage.setItem("notifications", JSON.stringify(newNotifications));
      return newNotifications;
    });
  };

  return (
    <div>
      <Box role="presentation">
        {dialog && (
          <Snackbar
            open={drawerOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={30000}
            onClose={hideDialog}
            message={
              <Box
                sx={{
                  width: "60vw",
                  maxWidth: "80vw",
                  "@media (max-width: 768px)": {
                    width: "80vw",
                  },
                }}
              >
                <Box flexGrow={1}>
                  <Typography
                    variant="caption"
                    sx={{
                      marginBottom: 2,
                      display: "block",
                    }}
                  >
                    {customFormat(dialog.timestamp, currentTime)}
                  </Typography>
                  <Typography variant="h6">{dialog.message}</Typography>
                  <Box role="presentation" p={2}>
                    <DataSelector
                      dialog={dialog}
                      onConfirm={handleDialogConfirm}
                      onIgnore={hideDialog}
                    ></DataSelector>
                  </Box>
                </Box>
              </Box>
            }
            ContentProps={{
              sx: {
                backgroundColor: theme.palette.background.card,
                color: theme.palette.text.primary,
                p: 2,
              },
            }}
          />
        )}
      </Box>

      {notification && (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={30000}
          onClose={() => {
            setNotification(null);
          }}
          message={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "60vw",
                maxWidth: "80vw",
                "@media (max-width: 768px)": {
                  width: "80vw",
                },
              }}
            >
              <Box flexGrow={1}>
                <Typography
                  variant="caption"
                  sx={{
                    marginBottom: 2,
                    display: "block",
                  }}
                >
                  {customFormat(notification.timestamp, currentTime)}
                </Typography>
                <Typography variant="h6">{notification.title}</Typography>
                <Typography>{notification.message}</Typography>
              </Box>
              <Box>
                <Box>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleDismissNotification}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          }
          ContentProps={{
            sx: {
              backgroundColor: theme.palette.background.card,
              color: theme.palette.text.primary,
              p: 2,
            },
          }}
        />
      )}
      <NotificationDialogList
        notifications={notifications}
        dialogs={dialogs}
        onClose={() => setListOpen(false)}
        onNotificationClick={handleNotificationClick}
        onDialogTimeout={handleDialogTimeout}
        onDialogClick={handleDialogClick}
        onClearNotifications={clearAllNotifications}
        open={listOpen}
      />
    </div>
  );
};

export default NotificationDialogSystem;
