import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";
import { useRecoilState } from "recoil";
import { platformState } from "../global-state";
import { Box, CircularProgress, Typography } from "@mui/material";

const UserLogout = () => {
  const history = useHistory();
  const [platform] = useRecoilState(platformState);

  useEffect(() => {
    handleLogout();
  }, []);

  const handleLogout = async () => {
    try {
      if (platform.source === "app") {
        if (window.Android) {
          window.Android.logout();
        }
      } else {
        Amplify.configure(USER_AUTH_CONFIG);
        await Auth.signOut();
        // Add a small delay for better UX
        setTimeout(() => {
          history.push("/user-login");
        }, 1500);
      }
    } catch (error) {
      console.error("Logout error:", error);
      history.push("/user-login");
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <CircularProgress size={40} />
      <Typography variant="h6" color="text.secondary">
        Logging out...
      </Typography>
    </Box>
  );
};

export default UserLogout;
