import React, { useContext, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { userIdTokenState, userInfoState } from "../global-state";
import { v4 as uuidv4 } from "uuid";
import { useEventEmitter } from "./event-context";
import { SAND_CAT_WS } from "../config";
import { useAuthCheck } from "../auth/auth-check";

const WebSocketContext = React.createContext();

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export const WebSocketProvider = ({ children }) => {
  const [wsReady, setWsReady] = useState(false);
  const [userInfo] = useRecoilState(userInfoState);
  const [userIdToken] = useRecoilState(userIdTokenState);
  const { emit } = useEventEmitter();
  const messageQueue = useRef({});
  const wsRef = useRef(null);
  const retryDuration = useRef(2000);
  const { checkAuthState, refreshUserToken } = useAuthCheck();
  const userIdTokenRef = useRef(userIdToken);
  const heartbeatInterval = useRef(null);
  const lastMessageTime = useRef(Date.now());
  const reconnectTimeout = useRef(null);
  const invisibleStartTime = useRef(null);
  const isPageVisible = useRef(true);

  useEffect(() => {
    userIdTokenRef.current = userIdToken;
  }, [userIdToken]);

  // Handle page visibility
  useEffect(() => {
    const handleVisibilityChange = () => {
      isPageVisible.current = document.visibilityState === "visible";
      if (isPageVisible.current) {
        // If page becomes visible and connection was closed due to inactivity
        if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
          // Use the current userInfo from Recoil state
          if (userInfo && userInfo.activeScope) {
            connectWebSocket();
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userInfo]);

  const startHeartbeat = () => {
    if (heartbeatInterval.current) {
      clearInterval(heartbeatInterval.current);
    }

    heartbeatInterval.current = setInterval(() => {
      if (wsRef.current?.readyState === WebSocket.OPEN) {
        // Check if there's been no activity for more than 30 seconds
        const timeSinceLastMessage = Date.now() - lastMessageTime.current;

        if (timeSinceLastMessage > 30000) {
          // 30 seconds
          // Send ping message
          try {
            wsRef.current.send(JSON.stringify({ type: "PING" }));
          } catch (error) {
            console.warn("Failed to send ping:", error);
            cleanupConnection();
          }
        }

        // If page is not visible, start tracking invisible time
        if (!isPageVisible.current) {
          if (!invisibleStartTime.current) {
            invisibleStartTime.current = Date.now();
          }

          // Check if it's been invisible for more than 10 minutes
          const invisibleDuration = Date.now() - invisibleStartTime.current;
          if (invisibleDuration > 600000) {
            // 10 minutes
            console.log(
              "Closing connection after 10 minutes of page invisibility"
            );
            cleanupConnection();
          }
        } else {
          // Reset the invisible start time when page becomes visible
          invisibleStartTime.current = null;
        }
      }
    }, 30000); // Check every 30 seconds
  };

  const cleanupConnection = () => {
    if (heartbeatInterval.current) {
      clearInterval(heartbeatInterval.current);
      heartbeatInterval.current = null;
    }
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current);
      reconnectTimeout.current = null;
    }
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    setWsReady(false);
  };

  const connectWebSocket = () => {
    if (
      wsRef.current &&
      (wsRef.current.readyState === WebSocket.OPEN ||
        wsRef.current.readyState === WebSocket.CONNECTING)
    ) {
      return;
    }

    console.log("WebSocket connecting... ");

    let socketUrl = SAND_CAT_WS;
    const customAddress = localStorage.getItem("localServiceAddress");
    if (customAddress) {
      socketUrl = SAND_CAT_WS.replace("localhost", customAddress);
    }
    socketUrl += `?authorization=Bearer ${userIdTokenRef.current}&scope=${userInfo.activeScope.id}`;

    const websocket = new WebSocket(socketUrl);

    websocket.onopen = () => {
      console.log("WebSocket Connection Established");
      setWsReady(true);
      wsRef.current = websocket;
      retryDuration.current = 200;
      lastMessageTime.current = Date.now();
      startHeartbeat();
    };

    websocket.onmessage = (event) => {
      lastMessageTime.current = Date.now();

      const data = JSON.parse(event.data);
      if (data.type === "PONG") {
        return;
      }
      if (
        data.type === "SERVER_RESP" &&
        data.sig &&
        messageQueue.current[data.sig]
      ) {
        messageQueue.current[data.sig].resolve(data);
        delete messageQueue.current[data.sig];
      }
      if (data.type === "SERVER_DATA") {
        emit(data.target, data);
      }
    };

    websocket.onclose = async (event) => {
      console.log("WebSocket connection closed:", event);
      setWsReady(false);
      emit("_closed_", null);

      if (event.reason === "UNAUTHENTICATED") {
        userIdTokenRef.current = await refreshUserToken();
      }

      // Only auto-reconnect if the page is visible and it wasn't closed due to inactivity
      if (isPageVisible.current) {
        reconnectTimeout.current = setTimeout(
          () => connectWebSocket(),
          retryDuration.current
        );
        if (retryDuration.current < 200000) {
          retryDuration.current = retryDuration.current * 2;
        }
      }
    };

    websocket.onerror = (error) => {
      console.error("WebSocket Error:", error);
      websocket.close();
    };

    wsRef.current = websocket;
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupConnection();
    };
  }, []);

  useEffect(() => {
    if (!userInfo || !userInfo.activeScope || userInfo.unchanged) {
      return;
    }
    cleanupConnection();
    connectWebSocket();
  }, [userInfo]);

  // Function to send a message and wait for a response
  const sendAndWaitWs = (target, payload) => {
    const message = {
      target,
      sig: uuidv4(),
      payload,
      type: "CLIENT_REQ",
    };
    return new Promise((resolve, reject) => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        messageQueue.current[message.sig] = { resolve, reject };
        wsRef.current.send(JSON.stringify(message));
      } else {
        reject(new Error("Websocket connection is not ready"));
      }
    });
  };

  // Function to send a message without waiting for a response
  const sendAndForgetWs = (target, payload) => {
    const message = {
      target,
      sig: uuidv4(),
      payload,
      type: "CLIENT_DATA",
    };
    return new Promise((resolve, reject) => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify(message));
        resolve({});
      } else {
        reject(new Error("Websocket connection is not ready"));
      }
    });
  };

  return (
    <WebSocketContext.Provider
      value={{
        ws: wsRef.current,
        wsReady,
        sendAndWaitWs,
        sendAndForgetWs,
        reconnect: connectWebSocket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
