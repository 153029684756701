export const searchLocation = async (apiCall, text) => {
  try {
    const resp = await apiCall(`/scope/location/search?text=${text}`, "GET");
    if (resp.error) {
      return null;
    }
    return resp;
  } catch (error) {
    return null;
  }
};

export const listLocations = async (apiCall, scopeId) => {
  try {
    const resp = await apiCall(`/scope/location/list?scope=${scopeId}`, "GET");
    if (resp.error) {
      return null;
    }
    return resp;
  } catch (error) {
    return null;
  }
};

export const saveLocation = async (apiCall, location) => {
  try {
    const resp = await apiCall(`/scope/location/save`, "POST", location);
    return resp && resp.success;
  } catch (error) {
    return false;
  }
};

export const deleteLocation = async (apiCall, location) => {
  try {
    const resp = await apiCall(`/scope/location/delete`, "POST", location);
    return resp && resp.success;
  } catch (error) {
    return false;
  }
};
