import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, CircularProgress } from "@mui/material";

function LoadingSpinner() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        p: 3,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default LoadingSpinner;
