export const saveScope = async (
  apiCall,
  scopeId,
  scopeName,
  active = false
) => {
  const request = { scopeId, scopeName, active };
  const response = await apiCall("/setting/scope/save", "POST", request);
  return response;
};

export const deleteScope = async (apiCall, scopeId) => {
  const request = { scopeId };
  const response = await apiCall("/setting/scope/delete", "POST", request);
  return response && response.success;
};

export const deleteScopeMembership = async (apiCall, scopeId, deleteEmail) => {
  const request = { scopeId, deleteEmail };
  const response = await apiCall(
    "/setting/scope/membership/delete",
    "POST",
    request
  );
  return response && response.success;
};

export const exitScope = async (apiCall, scopeId) => {
  const request = { scopeId };
  const response = await apiCall(
    "/setting/scope/membership/update",
    "POST",
    request
  );
  return response && response.success;
};

export const switchScope = async (apiCall, previousScopeId, currentScopeId) => {
  const request = { previousScopeId, currentScopeId };
  const response = await apiCall("/setting/scope/switch", "POST", request);
  return response && response.success;
};

export const inviteMember = async (apiCall, scopeId, inviteeEmails) => {
  const request = { scopeId, inviteeEmails };
  const response = await apiCall(
    "/setting/scope/membership/invite",
    "POST",
    request
  );
  return response && response.success;
};

export const updateMember = async (
  apiCall,
  scopeId,
  accept,
  active = false
) => {
  const request = { scopeId, accept, active };
  const response = await apiCall(
    "/setting/scope/membership/update",
    "POST",
    request
  );
  return response && response.success;
};

export const getScopeInfo = async (apiCall, scopeId) => {
  return await apiCall(`/setting/scope/get?scopeId=${scopeId}`, "GET");
};

export const saveScopeExecutionEnv = async (apiCall, scopeId, env) => {
  const request = { scopeId, env };
  const response = await apiCall("/scope/env/update", "POST", request);
  return response && response.success;
};

export const checkInvitation = async (apiCall) => {
  return await apiCall("/setting/scope/invitations/check", "GET");
};
