import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../theme";

const PremadeFilter = ({
  sortBy,
  setSortBy,
  setFilterText,
  setShowFilter,
  onTagChange,
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [selectedTagsLocal, setSelectedTagsLocal] = useState(["all"]);
  const tags = ["all", "click", "send", "create"];

  useEffect(() => {
    setFilterText(filterInput);
  });

  const handleFilterInputChange = (event) => {
    setFilterInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setFilterValue(filterInput);
    }
  };

  const handleTagChange = (tag) => {
    if (tag === "all") {
      setSelectedTagsLocal(["all"]);
    } else {
      setSelectedTagsLocal((prev) => {
        const newTags = prev.filter((t) => t != "all");
        if (prev.includes(tag)) {
          const filtered = newTags.filter((t) => t !== tag);
          return filtered.length === 0 ? ["all"] : filtered;
        } else {
          return [...newTags, tag];
        }
      });
    }
  };

  return (
    <Card
      sx={{ mb: 1, backgroundColor: theme.palette.background.paper }}
      elevation={0}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          p: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              flex: "1",
              mr: 2,
              height: "100%",
            }}
          >
            <TextField
              fullWidth
              value={filterInput}
              onChange={handleFilterInputChange}
              onKeyDown={handleKeyDown}
              placeholder="fliter text"
              variant="outlined"
              size="small"
              InputProps={{
                sx: {
                  backgroundColor: theme.palette.background.input,
                  borderRadius: "8px",
                },
              }}
              sx={{
                height: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                },
              }}
            />
          </Box>

          <FormControl
            variant="outlined"
            sx={{
              width: "auto",
            }}
          >
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              size="small"
              sx={{
                borderRadius: "8px",
                backgroundColor: theme.palette.background.input,
              }}
            >
              <MenuItem value="popularity">Popularity</MenuItem>
              <MenuItem value="alphabet">Name (A-Z)</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mt: 1,
          }}
        >
          {tags.map((tag) => (
            <Chip
              key={tag}
              label={tag.charAt(0).toLowerCase() + tag.slice(1)}
              onClick={() => handleTagChange(tag)}
              sx={{
                backgroundColor: selectedTagsLocal.includes(tag)
                  ? theme.palette.primary.main
                  : theme.palette.background.input,
                color: selectedTagsLocal.includes(tag)
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.primary,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: selectedTagsLocal.includes(tag)
                    ? theme.palette.primary.dark
                    : theme.palette.action.hover,
                },
                transition: "background-color 0.3s",
                borderRadius: "16px",
              }}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
};

export default PremadeFilter;
