import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DocStyleContent from "./doc-style-content";

export default function Doc({ docName = "" }) {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const theme = useTheme();

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const targetUrl = `https://mhome-legal.s3.us-east-1.amazonaws.com/${docName}.md`;
        const response = await fetch(`${targetUrl}`, {
          method: "GET",
          headers: {
            Accept: "text/markdown,text/plain,*/*",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch terms of use");
        }
        const text = await response.text();
        if (text) {
          setContent(text);
        } else {
          throw new Error("No content received");
        }
      } catch (err) {
        console.error("Error fetching terms:", err);
        setError("Failed to load privacy policy. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTerms();
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress size={48} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          color: "error.main",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {error}
          </Typography>
          <Typography
            component="button"
            onClick={() => window.location.reload()}
            sx={{
              mt: 2,
              color: "primary.main",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Try Again
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        bgcolor: "background.white",
        minHeight: "100vh",
        py: 3,
      }}
    >
      <DocStyleContent>
        <ReactMarkdown
          components={{
            h1: ({ node, ...props }) => (
              <Typography variant="h1" component="h1" {...props} />
            ),
            h2: ({ node, ...props }) => (
              <Typography variant="h2" component="h2" {...props} />
            ),
            p: ({ node, ...props }) => <Typography component="p" {...props} />,
            ul: ({ node, ...props }) => <Box component="ul" {...props} />,
            ol: ({ node, ...props }) => <Box component="ol" {...props} />,
            li: ({ node, ...props }) => <Box component="li" {...props} />,
          }}
        >
          {content}
        </ReactMarkdown>
      </DocStyleContent>
    </Container>
  );
}
