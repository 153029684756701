import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import LocationDialog from "./location-dialog";
import {
  deleteLocation,
  listLocations,
  saveLocation,
} from "./location-service";
import { useToast } from "../common/toast";
import useApiCall from "../common/api-call";
import { useHistory } from "react-router-dom";

const Location = () => {
  const theme = useTheme();
  const [userInfo] = useRecoilState(userInfoState);
  const [locations, setLocations] = useState([]);
  const [locationNames, setLocationNames] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editLocation, setEditLocation] = useState(null);
  const { successToast, warningToast, errorToast } = useToast();
  const history = useHistory();
  const { apiCall } = useApiCall();

  const isSmallScreen = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    const resp = await listLocations(apiCall, userInfo.activeScope.id);
    if (resp) {
      setLocations(resp.locations);
      setLocationNames(resp.locations.map((location) => location.name));
    } else {
      errorToast("Error!");
    }
  };

  const handleAddLocation = () => {
    setEditLocation(null);
    setDialogOpen(true);
  };

  const handleEditLocation = (location) => {
    setEditLocation(location);
    setDialogOpen(true);
  };

  const handleSaveLocation = async (locationData) => {
    locationData.scopeId = userInfo.activeScope.id;
    console.log(locationData);
    const resp = await saveLocation(apiCall, locationData);
    if (resp) {
      successToast("Saved");
      fetchLocations();
    } else {
      errorToast("Error!");
    }
  };

  const handleDelete = async (locationData) => {
    locationData.scopeId = userInfo.activeScope.id;
    const resp = await deleteLocation(apiCall, locationData);
    if (resp) {
      successToast("Deleted");
      fetchLocations();
    } else {
      errorToast("Error!");
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" p={2} width="100%">
      {isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              if (isSmallScreen) {
                history.goBack();
              } else {
                history.replace("/setting");
              }
            }}
            sx={{ mr: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Locations</Typography>
        </Box>
      )}

      <Card
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: "12px",
          border: "1px solid #EAEEF0",
          boxShadow: "none",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Saved Locations</Typography>
            <IconButton onClick={handleAddLocation} color="primary">
              <AddIcon />
            </IconButton>
          </Box>

          <List>
            {locations.map((location) => (
              <ListItem
                key={location.id}
                sx={{
                  mb: 1,
                  backgroundColor: theme.palette.background.card,
                }}
              >
                <ListItemText
                  primary={location.name}
                  secondary={location.address}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleEditLocation(location)}
                  >
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      <LocationDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setEditLocation(null);
        }}
        onSave={handleSaveLocation}
        onDelete={handleDelete}
        initialData={editLocation}
        locationNames={locationNames}
      />
    </Box>
  );
};

export default Location;
