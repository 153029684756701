import CodeEditor from "../common/code-editor";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import { ScriptSpecData } from "../lang/script-spec-data";
import { useTheme } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import OAuthFlow from "../common/oauth";
import LoadingSpinner from "../common/loading";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import DeleteAutomationDialog from "./delete-automation-dialog";
import {
  connectIntegration,
  deleteAutomation,
  disableAutomation,
  enableAutomation,
  formatAutomation,
  getAutomation,
  saveAutomationFullData,
} from "./automation-service";
import { useToast } from "../common/toast";
import useApiCall from "../common/api-call";
import MSwitch from "../common/switch";

const ScriptEditView = () => {
  const specDataRef = useRef(null);
  const history = useHistory();
  const theme = useTheme();
  const { apiCall } = useApiCall();
  const [queryParams, setQueryParams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [automation, setAutomation] = useState(null);
  const [automationId, setAutomationId] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [runnable, setRunnable] = useState(false);
  const [code, setCode] = useState("");
  const [parseResults, setParseResults] = useState([]);
  const [integrations, setIntegrations] = useState(new Map());
  const [currentIntegration, setCurrentIntegration] = useState("");
  const [unsaved, setUnsaved] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { successToast, warningToast, errorToast } = useToast();
  const [infoType, setInfoType] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setQueryParams(params);
    if (params["id"]) {
      handleGetAutomation(params);
    } else {
      setLoading(false);
    }
  }, []);

  const handleGetAutomation = async (params) => {
    setLoading(true);
    const resp = await getAutomation(
      apiCall,
      userInfo.activeScope.id,
      params.id
    );
    if (resp) {
      setAutomation(resp);
      setAutomationId(resp["id"]);
      setCode(resp["script"]);
      setParseResults(resp["parseResults"]);
      setRunnable(resp["runnable"]);
      setEnabled(resp["enabled"]);
      setLoading(false);
    } else {
      errorToast("Error");
    }
  };

  const handleInfoTypeChange = (event, newValue) => {
    setInfoType(newValue);
  };

  const handleScriptContext = async (context) => {
    // if (context.integration) {
    //   await loadConnectInfo(context.integration);
    //   setCurrentIntegration(context.integration);
    // } else {
    //   setCurrentIntegration("");
    // }
  };

  const loadConnectInfo = async (integration) => {
    if (integrations.has(integration)) {
      return;
    }
    const resp = await connectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      const integrationData = {};
      if (resp.oAuthConnect) {
        integrationData.oAuthConnect = resp.oAuthConnect;
      }
      console.log(integrationData.oAuthConnect);
      const ucs = resp.userConnections || [];
      const uc = [];
      for (let i = 0; i < ucs.length; i++) {
        uc.push({ id: i, user: ucs[i].username });
      }
      integrationData.userConnections = uc;
      integrations.set(integration, integrationData);
    } else {
      errorToast("Failed to load integration info");
    }
  };

  const saveScript = async () => {
    try {
      const jsonData = await saveAutomationFullData(
        apiCall,
        code,
        userInfo.activeScope.id,
        automationId
      );
      if (jsonData["runnable"]) {
        successToast("Saved. Ready to activate");
        setRunnable(true);
      } else {
        warningToast("Saved. Script has errors");
        setRunnable(false);
      }
      setUnsaved(false);
      setEnabled(false);
      setParseResults(jsonData["parseResults"]);
      if (!automationId) {
        history.replace(`/automation/script?id=${jsonData["automationId"]}`);
        setAutomationId(jsonData["automationId"]);
      }
    } catch (error) {
      console.error("Error saving draft:", error);
    }
  };

  const toggleEnableDisable = async (event, enableDisable) => {
    if (enableDisable) {
      const success = await enableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Enabled");
        setEnabled(true);
      } else {
        errorToast("Failed to enable");
        setEnabled(false);
      }
    } else {
      const success = await disableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Disabled");
        setEnabled(false);
      } else {
        errorToast("Failed to disable");
        setEnabled(true);
      }
    }
  };

  const handleDelete = async () => {
    const success = await deleteAutomation(
      apiCall,
      userInfo.activeScope.id,
      automationId,
      true
    );
    if (success) {
      history.push("/automation/list");
    } else {
      errorToast("Fail to delete");
    }
  };

  const formatScript = async () => {
    const resp = await formatAutomation(apiCall, code, userInfo.activeScope.id);
    if (resp) {
      if (resp["success"]) {
        setCode(resp["script"]);
        setUnsaved(true);
        successToast("Formatted");
      } else {
        errorToast("Script has errors");
      }
      setParseResults(resp["parseResults"]);
    } else {
      errorToast("Format error");
    }
  };

  const goOverview = () => {
    if (automationId) {
      history.replace(`/automation/overview?id=${automationId}`);
    } else {
      history.replace(`/automation/overview`);
    }
  };

  const handleOAuthFailure = () => {
    errorToast("Error");
  };

  const handleOAuthSuccess = () => {
    successToast("User connected.");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
          >
            <Grid item sx={{ width: "auto", flexGrow: 0, flexShrink: 1 }}>
              <div style={{ display: "inline-block" }}>
                <Box display="flex" alignItems="center">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs>
                      <Button
                        variant="contained"
                        onClick={saveScript}
                        disabled={!unsaved}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        variant="contained"
                        onClick={formatScript}
                        // disabled={!runnable}
                      >
                        Format
                      </Button>
                    </Grid>
                    <Grid item xs>
                      {runnable && (
                        <MSwitch
                          checked={enabled}
                          disabled={!runnable || unsaved}
                          onChange={toggleEnableDisable}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <IconButton
                        sx={{ color: theme.palette.background.l5 }}
                        aria-label="delete"
                        onClick={() => setDeleteConfirm(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs>
                      <Button variant="contained" onClick={goOverview}>
                        overview
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={11}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CodeEditor
                      value={code}
                      editable={true}
                      parseResults={parseResults}
                      // specDataRef={specDataRef}
                      onPreParse={handleScriptContext}
                      height={"800px"}
                      onChange={(instance) => {
                        const code = instance.getValue();
                        setCode(code);
                        setUnsaved(true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2} direction="column">
                      <Grid item xs={2}></Grid>
                      <Grid item xs={10}>
                        <Box sx={{ height: 800, width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={infoType}
                              onChange={handleInfoTypeChange}
                              aria-label="Info type"
                            >
                              <Tab label="Connect" />
                              <Tab label="Document" />
                            </Tabs>
                          </Box>
                          {infoType === 0 && (
                            <Box sx={{ flexGrow: 1 }}>
                              {currentIntegration ? (
                                <div>
                                  <h2>{currentIntegration}</h2>
                                  <OAuthFlow
                                    oAuthInfo={
                                      integrations.get(currentIntegration)
                                        .oAuthConnect
                                    }
                                    onSuccess={handleOAuthSuccess}
                                    onFailure={handleOAuthFailure}
                                  ></OAuthFlow>
                                  <OAuthConnectInfo
                                    infos={
                                      integrations.get(currentIntegration)
                                        .userConnections
                                    }
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Box>
                          )}
                          {infoType === 1 && <div>Document</div>}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <ScriptSpecData ref={specDataRef} />
          <DeleteAutomationDialog
            open={deleteConfirm}
            onClose={() => setDeleteConfirm(false)}
            onDelete={handleDelete}
          ></DeleteAutomationDialog>
        </Box>
      )}
    </div>
  );
};

const OAuthConnectInfo = ({ infos }) => {
  const columns = [
    { field: "id", headerName: "ID", width: 10, hide: true },
    {
      field: "user",
      headerName: "User",
      width: 200,
      sortable: true,
    },
    {
      field: "revoke",
      headerName: "",
      description: "Disconnect the user",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              // Handle button click here
              console.log(`Button clicked for row with ID ${params.row.id}`);
            }}
          >
            Disconnect
          </Button>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={infos}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
        columns: {
          ...columns,
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      pageSizeOptions={[20]}
      disableRowSelectionOnClick
      rowsPerPageOptions={[20]}
      checkboxSelection={false}
    />
  );
};

export default ScriptEditView;
