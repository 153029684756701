import React from "react";
import { Typography, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const UserChatCard = React.memo(({ text }) => {
  const theme = useTheme();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" mb={2}>
      <Box
        sx={{
          maxWidth: "80%",
          backgroundColor: theme.palette.background.c1,
          borderRadius: "12px",
          padding: "8px",
          mb: 0.5, // Add space between bubble and copy button
        }}
      >
        <Typography variant="body1">{text}</Typography>
      </Box>

      <IconButton
        size="small"
        onClick={handleCopy}
        sx={{
          opacity: 0.7,
          padding: "4px",
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Box>
  );
});

UserChatCard.displayName = "UserChatCard";

export default UserChatCard;
