import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { fixedSelectCheck } from "./fixed-select-check";
import { styled, useTheme } from "@mui/material/styles";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.button,
    color: theme.palette.text.white,
    "&:hover": {
      backgroundColor: theme.palette.background.button,
    },
  },
}));

const FixedSelectInput = ({
  valueSpec,
  onChange,
  onError,
  useBiToggle = false,
  multiSelect = false,
  style = "standard",
}) => {
  const [value, setValue] = useState(multiSelect ? [] : null);
  const [error, setError] = useState(null);
  const [enums, setEnums] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setValue(valueSpec.value);
    setEnums(valueSpec.enums || []);
    const err = checkValue(valueSpec.value, valueSpec);
    if (err) {
      setError(true);
      onError(true);
    }
    return () => {};
  }, [valueSpec]);

  const handleToggle = (e, v) => {
    const err = checkValue(v, valueSpec);
    setValue(v);
    setError(err);
    onChange(v);
    onError(err);
  };

  const checkValue = (value, spec) => {
    if (multiSelect) {
      for (let i = 0; i < value.length; i++) {
        const err = !fixedSelectCheck(value[i], spec);
        if (err) {
          return err;
        }
      }
      return false;
    } else {
      const err = !fixedSelectCheck(value, spec);
      return err;
    }
  };

  const handleChange = (e) => {
    const err = !fixedSelectCheck(e.target.value, valueSpec);
    setValue(e.target.value);
    setError(err);
    onChange(e.target.value);
    onError(err);
  };

  const compactStyles = {
    height: "100%",
    "& .MuiInputBase-root": {
      height: "100%",
    },
    "& .MuiSelect-select": {
      height: "100% !important",
      padding: "0 32px 0 8px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 1,
    },
  };

  const standardStyles = {
    "& .MuiInputBase-root": {
      minHeight: "56px",
    },
    "& .MuiSelect-select": {
      padding: "16px 32px 16px 14px",
    },
  };

  const compactLabelStyles = {
    transform: "translate(8px, -6px) scale(0.75)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(8px, -6px) scale(0.75)",
    },
  };

  const standardLabelStyles = {
    transform: "translate(14px, 16px)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  };

  const compactMenuItemStyles = {
    minHeight: "24px",
    padding: "4px 8px",
  };

  const standardMenuItemStyles = {
    minHeight: "auto",
    padding: "6px 16px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        p: style === "compact" ? "2px" : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: style === "compact" ? 1 : 2,
        }}
      >
        {valueSpec.name && (
          <Typography
            variant="h6"
            sx={{
              flexShrink: 0,
              mb: style === "compact" ? 1 : 2,
            }}
          >
            {valueSpec.name}
          </Typography>
        )}
        <Box
          sx={{
            flex: 1,
            minHeight: 0,
            position: "relative",
          }}
        >
          {useBiToggle && !multiSelect && enums.length === 2 ? (
            <ToggleButtonGroup
              value={value}
              exclusive
              fullWidth
              onChange={handleToggle}
              sx={{
                height: style === "compact" ? "100%" : "56px",
                backgroundColor: value
                  ? theme.palette.background.l0
                  : theme.palette.background.l1,
                "& .MuiToggleButtonGroup-grouped": {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <CustomToggleButton value={valueSpec.enums[0]}>
                {valueSpec.enums[0]}
              </CustomToggleButton>
              <CustomToggleButton value={valueSpec.enums[1]}>
                {valueSpec.enums[1]}
              </CustomToggleButton>
            </ToggleButtonGroup>
          ) : (
            <>
              {value !== null && (
                <FormControl
                  fullWidth
                  sx={style === "compact" ? compactStyles : standardStyles}
                >
                  <InputLabel
                    id="simple-select-label"
                    sx={
                      style === "compact"
                        ? compactLabelStyles
                        : standardLabelStyles
                    }
                  >
                    {multiSelect ? "Select values" : "Select a value"}
                  </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={value}
                    label="Select an Option"
                    onChange={handleChange}
                    multiple={multiSelect}
                    error={error}
                    renderValue={(selected) =>
                      multiSelect ? selected.join(", ") : selected
                    }
                  >
                    {enums.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={
                          style === "compact"
                            ? compactMenuItemStyles
                            : standardMenuItemStyles
                        }
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FixedSelectInput;
