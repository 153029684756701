import React, { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";
import { Box, Button, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useApiCall from "../common/api-call";

const UserLink = () => {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const { apiCall } = useApiCall();
  const [status, setStatus] = useState("");
  const [orgInfo, setOrgInfo] = useState(null);

  useEffect(() => {
    setStatus("loading");
    Amplify.configure(USER_AUTH_CONFIG);

    // Extract the 'org' query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const orgId = queryParams.get("org");

    if (orgId) {
      fetchOrgInfo(orgId);
    } else {
      setStatus("error");
    }
  }, [location.search]);

  const fetchOrgInfo = async (orgId) => {
    const response = await apiCall(`/org/info?id=${orgId}`, "GET", null);
    if (response) {
      setOrgInfo(response);
      setStatus("loaded");
    } else {
      setStatus("error");
    }
  };

  const handleLink = () => {
    if (orgInfo) {
      history.push(orgInfo.oAuthUrl); // Redirect to the org's OAuth URL
    }
  };

  const handleLogout = async () => {
    Amplify.configure(USER_AUTH_CONFIG);
    await Auth.signOut();
    history.push("/user-login");
  };

  return (
    <>
      {status === "loaded" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            textAlign: "center",
            px: 2,
            backgroundColor: theme.palette.background.l1,
          }}
        >
          <Typography variant="h5" gutterBottom>
            {`Your mHome account is not linked to ${orgInfo.name}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please choose one of the options below to proceed.
          </Typography>
          <Box sx={{ mt: 4, display: "flex", gap: 2 }}>
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Switch Account
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLink}
              disabled={!orgInfo}
            >
              Start Linking
            </Button>
          </Box>
        </Box>
      )}
      {status === "error" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            textAlign: "center",
            px: 2,
            backgroundColor: theme.palette.background.l1,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Failed to access organization info.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default UserLink;
