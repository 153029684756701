import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  Stack,
  IconButton,
  useMediaQuery,
  Paper,
  Grid,
  Collapse,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentIcon from "@mui/icons-material/Payment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useHistory } from "react-router-dom";
import { getCredits, getPayments, startCheckout } from "./billing-service";
import useApiCall from "../common/api-call";
import LoadingSpinner from "../common/loading";
import { customFormat } from "../common/custom-format";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const Billing = () => {
  const { apiCall } = useApiCall();
  const buttonRef = useRef(null);
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState({});
  const [payments, setPayments] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    handleGetCredits();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleGetCredits = async () => {
    setLoading(true);
    const resp1 = await getCredits(apiCall);
    const resp2 = await getPayments(apiCall);
    console.log(resp1);
    console.log(resp2);
    setCredits(resp1 || {});
    // setPayments(resp2?.payments || []);
    setPayments(
      resp2?.payments || [
        {
          description: "100 credits",
          amount: 100,
          currency: "USD",
          timestamp: 111111111111,
        },
        {
          description: "100 credits",
          amount: 100,
          currency: "USD",
          timestamp: 111111111111,
        },
      ]
    );

    setLoading(false);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="start"
          flexDirection="column"
          height="100%"
          p={2}
          width="100%"
        >
          <Box
            position="static"
            sx={{
              display: "flex",
              alignItems: "center",
              justfyContent: "flex-start",
              mb: 2,
            }}
          >
            {isSmallScreen && (
              <IconButton
                color="inherit"
                edge="start"
                sx={{ mr: 1 }}
                onClick={() => {
                  if (isSmallScreen) {
                    history.goBack();
                  } else {
                    history.replace("/setting");
                  }
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Box sx={{ display: "flex" }}>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Billing
              </Typography>
            </Box>
          </Box>

          <Container maxWidth="lg" sx={{ py: 3 }}>
            <Stack spacing={4} alignItems="center">
              <Card sx={{ width: "100%", maxWidth: 400, mx: "auto" }}>
                <CardContent sx={{ p: 2 }}>
                  <Stack spacing={1}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6">Your Credits</Typography>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h4" color="primary">
                          {(credits?.onDemandBalance || 0) +
                            (credits?.refreshBalance || 0)}
                        </Typography>
                        <ExpandMore
                          expand={expanded}
                          onClick={() => setExpanded(!expanded)}
                          aria-expanded={expanded}
                          aria-label="show more"
                          size="small"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </Box>
                    </Box>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <Stack spacing={1}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center">
                            <AutorenewIcon
                              sx={{ mr: 1, fontSize: "1.2rem" }}
                              color="primary"
                            />
                            <Typography sx={{ fontSize: "0.95rem" }}>
                              Free Credits:
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                              {credits?.refreshBalance || 0}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center">
                            <ShoppingCartIcon
                              sx={{ mr: 1, fontSize: "1.2rem" }}
                              color="primary"
                            />
                            <Typography sx={{ fontSize: "0.95rem" }}>
                              Purchased Credits:
                            </Typography>
                          </Box>
                          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                            {credits?.onDemandBalance || 0}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 1 }}
                          >
                            <WorkspacePremiumIcon
                              sx={{ mr: 1, fontSize: "1.2rem" }}
                              color="primary"
                            />
                            <Typography
                              color="text.secondary"
                              sx={{ fontSize: "0.85rem" }}
                            >
                              Current Tier: {credits?.tier}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <AutorenewIcon
                              sx={{ mr: 1, fontSize: "1.2rem" }}
                              color="primary"
                            />
                            <Typography
                              color="text.secondary"
                              sx={{ fontSize: "0.85rem" }}
                            >
                              Next Refresh Date:{" "}
                              {customFormat(
                                dayjs(credits?.nextRefreshTimestamp),
                                currentTime
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                    </Collapse>
                  </Stack>
                </CardContent>
              </Card>
              <Box textAlign="center">
                <Typography variant="h3" gutterBottom>
                  Add Credits
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Choose a package to add credits to your account
                </Typography>
              </Box>
              <Card sx={{ width: "100%", maxWidth: 400, mx: "auto" }}>
                <CardContent>
                  <Stack spacing={3}>
                    <Box textAlign="center">
                      <Typography variant="h5" gutterBottom>
                        100 Credits
                      </Typography>
                      <Typography
                        variant="h4"
                        color="primary"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        $1.00
                      </Typography>
                    </Box>

                    <Divider />

                    <Box>
                      <Button
                        ref={buttonRef}
                        variant="contained"
                        fullWidth
                        size="large"
                        startIcon={<PaymentIcon />}
                        onClick={async () => {
                          try {
                            const response = await startCheckout(
                              apiCall,
                              "c100"
                            );
                            if (response) {
                              window.location.href = response.url;
                            }
                          } catch (error) {
                            console.error(
                              "Error creating checkout session:",
                              error
                            );
                          }
                        }}
                      >
                        Purchase
                      </Button>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
              <Card sx={{ width: "100%", maxWidth: 400, mx: "auto" }}>
                <CardContent>
                  <Stack spacing={3}>
                    <Box textAlign="center">
                      <Typography variant="h5" gutterBottom>
                        600 Credits
                      </Typography>
                      <Typography
                        variant="h4"
                        color="primary"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        $5.00
                      </Typography>
                    </Box>

                    <Divider />

                    <Box>
                      <Button
                        ref={buttonRef}
                        variant="contained"
                        fullWidth
                        size="large"
                        startIcon={<PaymentIcon />}
                        onClick={async () => {
                          try {
                            const response = await startCheckout(
                              apiCall,
                              "c500"
                            );
                            if (response) {
                              window.location.href = response.url;
                            }
                          } catch (error) {
                            console.error(
                              "Error creating checkout session:",
                              error
                            );
                          }
                        }}
                      >
                        Purchase
                      </Button>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>

              <Box textAlign="center">
                <Typography variant="h3" gutterBottom>
                  Payment History
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Your transaction records
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                {payments.map((payment, index) => (
                  <Card
                    key={index}
                    sx={{ width: "100%", maxWidth: 400, mx: "auto", mb: 1 }}
                  >
                    <CardContent sx={{ p: 1, "&:last-child": { pb: 1 } }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                            {payment.description}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              color: "primary.main",
                              fontWeight: "bold",
                              fontSize: "1rem",
                            }}
                          >
                            ${(payment.amount / 100).toFixed(2)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              fontSize: "0.75rem",
                            }}
                          >
                            {customFormat(
                              dayjs(payment.timestamp),
                              currentTime
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>

              {payments.length === 0 && (
                <Card sx={{ maxWidth: 400, mx: "auto", width: "100%" }}>
                  <CardContent>
                    <Typography color="text.secondary" textAlign="center">
                      No payment history available
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Stack>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Billing;
