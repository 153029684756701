import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useWebSocket } from "../ws/websocket";
import LoadingSpinner from "../common/loading";
import { useEventEmitter } from "../ws/event-context";
import InstructionInput from "../assistant/instruction-input";
import { useBackdrop } from "../common/backdrop";
import UserChatCard from "./user-chat-card";
import AssistantChatCard from "./assistant-chat-card";
import { complete, stt } from "../assistant/assistant-service";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import useApiCall from "../common/api-call";
import { useTheme } from "@mui/material/styles";
import { useToast } from "../common/toast";

const ChatView = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { subscribe } = useEventEmitter();
  const { apiCall } = useApiCall();
  const [userInfo] = useRecoilState(userInfoState);
  const [outdated, setOutdated] = useState(false);
  const [currentContext, setCurrentContext] = useState(null);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const { successToast, warningToast, errorToast } = useToast();
  const theme = useTheme();

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "_closed_") {
        setOutdated(true);
        openBackdrop("Please Refresh");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      closeBackdrop();
      setLoading(false);
    }
  }, [ws, ws?.readyState]);

  const handleAutomationSave = (sid, aid) => {
    setMessages((prevMessages) => {
      let updatedMessages = [...prevMessages];
      let existingMessageIndex = updatedMessages.findLastIndex(
        (prevMessage) =>
          prevMessage.from === "assistant" &&
          prevMessage.payload.type === "AUTOMATION" &&
          prevMessage.payload.data &&
          prevMessage.payload.data.sessionId === sid
      );
      if (existingMessageIndex !== -1) {
        updatedMessages[existingMessageIndex] = {
          from: "assistant",
          payload: {
            ...updatedMessages[existingMessageIndex].payload,
            content: null,
            data: { sessionId: sid, automationId: aid },
          },
        };
      }
      return updatedMessages;
    });
  };

  const handleAutomationDelete = (aid) => {
    setMessages((prevMessages) => {
      let updatedMessages = [...prevMessages];
      let existingMessageIndex = updatedMessages.findLastIndex(
        (prevMessage) =>
          prevMessage.from === "assistant" &&
          prevMessage.payload.type === "AUTOMATION" &&
          prevMessage.payload.data &&
          prevMessage.payload.data.automationId === aid
      );
      if (existingMessageIndex !== -1) {
        const n = { ...updatedMessages[existingMessageIndex] };
        const np = { ...n.payload };
        np.content = null;
        np.data.automationId = null;
        n.payload = np;
        updatedMessages[existingMessageIndex] = n;
      }
      return updatedMessages;
    });
  };

  const handleReplay = async () => {
    console.log(currentContext.instruction);
    await handleSendMessage(currentContext.instruction, null);
  };

  const handleSendMessage = async (instruction, audioBlob) => {
    const newMessage = {
      from: "user",
      text: audioBlob ? "Voice:" : instruction,
    };
    setMessages((prevMessages) => {
      let updatedMessages = [...prevMessages];
      updatedMessages.push(newMessage);
      return updatedMessages;
    });

    let ins = instruction;
    if (audioBlob) {
      ins = await stt(apiCall, audioBlob);
      if (ins) {
        console.log(ins);
        const voiceMessage = {
          from: "user",
          text: "Voice: " + ins,
        };
        setMessages((prevMessages) => {
          let updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = voiceMessage;
          return updatedMessages;
        });
      } else {
        const voiceMessage = {
          from: "user",
          text: "Voice error!",
        };
        setMessages((prevMessages) => {
          let updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = voiceMessage;
          return updatedMessages;
        });
        errorToast("Error!");
        return;
      }
    }
    setIsWaitingResponse(true);
    // TODO: provide context messages
    const packContext = { instruction: ins, contextMessages: [] };
    const resp = await complete(
      sendAndWaitWs,
      userInfo.activeScope.id,
      userInfo.activeScope.untrusted,
      packContext,
      0,
      userInfo.setting.debugMode
    );

    setIsWaitingResponse(false);

    if (resp && resp.messages) {
      console.log(resp);
      setCurrentContext(resp.currentContext);
      setMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];
        resp.messages.forEach((newMessage) => {
          let existingMessageIndex = -1;
          if (newMessage.type === "AUTOMATION") {
            existingMessageIndex = updatedMessages.findLastIndex(
              (prevMessage) =>
                prevMessage.from === "assistant" &&
                prevMessage.payload.type === "AUTOMATION" &&
                prevMessage.payload.data &&
                newMessage.data &&
                prevMessage.payload.data.sessionId === newMessage.data.sessionId
            );
          }
          if (existingMessageIndex !== -1) {
            updatedMessages[existingMessageIndex] = {
              from: "assistant",
              payload: {
                type: "AUTOMATION",
                content: null,
                data: {
                  sessionId: newMessage.data.sessionId,
                  automationId: null,
                },
              },
            };
          }
          updatedMessages.push({
            from: "assistant",
            payload: newMessage,
          });
        });

        return updatedMessages;
      });
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          from: "assistant",
          payload: {
            type: "ERROR",
            content: "Assistant Error.",
          },
          timestamp: new Date(),
        },
      ]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "absolute", // Make it absolute relative to the parent flex container
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pt: {
          xs: "56px", // Mobile AppBar height
          sm: "64px", // Desktop AppBar height
        },
        pb: {
          xs: "80px",
        },
        bgcolor: theme.palette.background.default,
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              px: 2,
              pb: "80px", // Space for input bar
              height: "100%",
              position: "relative",
            }}
          >
            {messages.map((message, index) =>
              message.from === "user" ? (
                <UserChatCard key={index} text={message.text} />
              ) : (
                <AssistantChatCard
                  key={index}
                  payload={message.payload}
                  onSave={handleAutomationSave}
                  onReplay={handleReplay}
                  onDelete={handleAutomationDelete}
                />
              )
            )}
            {isWaitingResponse && (
              <AssistantChatCard
                payload={{
                  type: "WAITING",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: theme.palette.background.paper,
              borderTop: 1,
              borderColor: "divider",
              zIndex: 1200,
            }}
          >
            <InstructionInput
              placeholder="Instruction here..."
              onSendText={(text) => handleSendMessage(text, null)}
              onSendAudio={(audio) => handleSendMessage(null, audio)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ChatView;
