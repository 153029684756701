import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useTheme } from "@mui/material/styles";
import { useToast } from "../common/toast";
import useApiCall from "../common/api-call";
import {
  listAutomations,
  runningAutomation,
} from "../automation/automation-service";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import AutomationFilter from "../automation/automation-filter";
import CircularProgress from "@mui/material/CircularProgress";
import { useWebSocket } from "../ws/websocket";
import { useEventEmitter } from "../ws/event-context";

const TaskFlowListView = ({ showFilter = false, setShowFilter }) => {
  const history = useHistory();
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [automations, setAutomations] = useState([]);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [sortBy, setSortBy] = useState("alphabet");
  const [filterText, setFilterText] = useState("");
  const [isShowFilter, setIsShowFilter] = useState(showFilter);
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const { subscribe } = useEventEmitter();
  const [selectedTags, setSelectedTags] = useState(["all"]);
  const [tags, setTags] = useState(["all"]);
  const lastStatusTimestampRef = useRef(0);

  useEffect(() => {
    setIsShowFilter(showFilter);
  }, [showFilter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleListTaskFlows();
  }, []);

  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      handleListTaskFlows();
    }
  }, [ws, ws?.readyState]);

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "/automation/history/update") {
        const payload = JSON.parse(data.payload);
        if (payload) {
          updateAutomation(payload);
        }
      } else if (target === "/automation/runningStatus/update") {
        const payload = JSON.parse(data.payload);
        console.log(
          payload && payload.timestamp > lastStatusTimestampRef.current
        );
        if (payload && payload.timestamp > lastStatusTimestampRef.current) {
          lastStatusTimestampRef.current = payload.timestamp;
          updateAutomation(payload);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, [automations]);

  const updateAutomation = (payload) => {
    console.log(payload);
    setAutomations((prevAutomations) => {
      const newAutomations = prevAutomations.map((automation) => {
        if (automation.id === payload.automationId) {
          return {
            ...automation,
            timestamp: payload.timestamp,
            running: payload.running || false,
          };
        }
        return automation;
      });
      console.log(newAutomations);
      return newAutomations;
    });
  };

  const sortedAutomations =
    automations
      .filter((a) => a.name.toLowerCase().includes(filterText.toLowerCase()))
      .sort((a, b) => {
        if (sortBy === "time") {
          const aTimestamp = a.timestamp
            ? dayjs(a.timestamp).valueOf()
            : Number.NEGATIVE_INFINITY;
          const bTimestamp = b.timestamp
            ? dayjs(b.timestamp).valueOf()
            : Number.NEGATIVE_INFINITY;
          return bTimestamp - aTimestamp;
        } else if (sortBy === "alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return -1;
          if (!aIsLetter && bIsLetter) return 1;
          return aName.localeCompare(bName);
        } else if (sortBy === "opp-time") {
          const aTimestamp = a.timestamp
            ? dayjs(a.timestamp).valueOf()
            : Number.POSITIVE_INFINITY;
          const bTimestamp = b.timestamp
            ? dayjs(b.timestamp).valueOf()
            : Number.POSITIVE_INFINITY;
          return aTimestamp - bTimestamp;
        } else if (sortBy === "opp-alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return 1;
          if (!aIsLetter && bIsLetter) return -1;
          return bName.localeCompare(aName);
        }
      }) || [];

  const handleListTaskFlows = async () => {
    setLoading(true);
    try {
      const resp = await listAutomations(apiCall, userInfo.activeScope.id);
      if (resp && Array.isArray(resp.automations)) {
        const taskFlowAutomations = resp["automations"].filter(
          (automation) => automation.type === "TASK_FLOW"
        );

        const allTags = taskFlowAutomations.reduce(
          (acc, automation) => {
            if (Array.isArray(automation.tags) && automation.tags.length > 0) {
              automation.tags.forEach((tag) => {
                if (!acc.includes(tag)) {
                  acc.push(tag);
                }
              });
            }
            return acc;
          },
          ["all"]
        );

        setTags(allTags);

        setAutomations(taskFlowAutomations);
        setLoading(false);
      } else {
        setAutomations([]);
      }
    } catch (error) {
      console.error("Failed to fetch automations:", error);
      setAutomations([]);
    } finally {
      setLoading(false);
    }
  };

  const openOverview = (data) => {
    history.push(`/automation/overview?id=${data.id}`);
  };

  const handleStartTaskFlow = async (event, runningStatus, automationId) => {
    if (runningStatus) {
      const success = await runningAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        // pass
      } else {
        errorToast("Error starting the task flow.");
      }
    }
  };

  const handleTagsChange = (tags) => {
    setSelectedTags(tags);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ flexGrow: 1, m: 2, pb: 12 }}>
          {isShowFilter && (
            <AutomationFilter
              sortBy={sortBy}
              setSortBy={setSortBy}
              setFilterText={setFilterText}
              onTagsChange={handleTagsChange}
              tags={tags}
            />
          )}
          {automations.length !== 0 ? (
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 2, sm: 4, md: 12 }}
            >
              {sortedAutomations
                .filter((automation) => {
                  if (selectedTags.includes("all")) return true;
                  return (
                    automation.tags.filter((x) => selectedTags.includes(x))
                      .length === selectedTags.length
                  );
                })
                .map((automation, index) => (
                  <Grid item xs={2} sm={2} md={4} key={index}>
                    <Card elevation={0}>
                      <CardActionArea
                        onClick={() => openOverview(automation)}
                        style={{
                          backgroundColor: theme.palette.background.card,
                        }}
                      >
                        <CardContent sx={{ padding: "8px" }}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              gutterBottom
                              variant="body1"
                              component="div"
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxHeight: "4.5em",
                                marginBottom: "0px",
                              }}
                            >
                              {automation.name}
                            </Typography>
                            {automation.runnable && automation.running ? (
                              <CircularProgress size={24} />
                            ) : automation.runnable ? (
                              <PlayCircleFilledWhiteIcon
                                disabled={!automation.runnable}
                                onClick={(e) => {
                                  handleStartTaskFlow(e, true, automation.id);
                                  e.stopPropagation();
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: theme.palette.background.button,
                                }}
                              />
                            ) : (
                              <ErrorIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            )}
                          </Box>
                          {automation.timestamp !== 0 && (
                            <Box>
                              <Typography
                                variant="caption"
                                component="div"
                                sx={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginTop: "5px",
                                  color: theme.palette.text.gray,
                                }}
                              >
                                {customFormat(
                                  dayjs(automation.timestamp),
                                  currentTime
                                )}
                              </Typography>
                            </Box>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No task flows
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default TaskFlowListView;
