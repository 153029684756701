export const getHistory = async (apiCall, scopeId, automationId) => {
  return await apiCall(
    `/automation/history/get?scope=${scopeId}&id=${automationId}`,
    "GET"
  );
};

export const getHistoryList = async (apiCall, scopeId) => {
  return await apiCall(`/automation/history/list?scope=${scopeId}`, "GET");
};
